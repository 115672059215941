import { createSlice } from "@reduxjs/toolkit";

const FetchFlagSlice = createSlice({
    name: 'fetchFlag',
    initialState: {
        isFetching: false,
    },
    reducers: {
        fetchFlagStart: (state) => {
            state.isFetching = true;
        },
        fetchFlagEnd: (state) => {
            state.isFetching = false;
        }
    }
})

export const {fetchFlagStart, fetchFlagEnd} = FetchFlagSlice.actions;

export default FetchFlagSlice.reducer;