import React, {useState, useEffect} from 'react';
import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserGroupIcon } from "@heroicons/react/24/solid";

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";
import {fetchDepartment, fetchDepartmentSuccess } from '../store/slices/department.slice';
import { useNavigate } from "react-router-dom";

import ApiService from '../services/api/api.service';

import ReactPaginate from "react-paginate";

const TABS = [
    {
        label: "All",
        value: "all",
        },
        {
        label: "Active",
        value: "active",
        },
        {
        label: "Inactive",
        value: "inactive",
    },
];

const TABLE_HEAD = ["ID", "Department", "Email", "Location", ""];

const DepartmentListPage = () => {

    const pageTitle = "Manage Departments";
    const pageDescription = "Btowse list, add, edit and delete departments.";

    const BreadcrumbItems = [
      { label: "Home", url: "/" },
      { label: "Departments", url: "/manage-departments" }   
    ]

    const randomMix = Math.floor(Math.random() * (100 - 2 + 1)) + 2;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [pageNumber, setPageNumber] = useState(0);
    const [searchFor, setSearchFor] = useState("");

    const isFetchingDepartment = useSelector((state) => state.departmentReducer.isFetchingDepartment);
    const departments = useSelector((state) => state.departmentReducer.departments);

    const viewAll = () => {
      setSearchFor("");
      setPageNumber(0);
    }
    
    const searchHandler = (e) => {
        setPageNumber(0);
        setSearchFor(e.target.value);
    }

    const filteredDepartments = departments.filter((department) => {
        let found = false;
        if(searchFor === ""){
          found = true;
        } else if (department.Name.toLowerCase().includes(searchFor.toLocaleLowerCase())) {
          found = true;
        }

        return found;
    })

    // Pagination Vars and States    
    const itemsPerPage = 5;
    const pagesVisited = pageNumber * itemsPerPage;
    const pageCount = Math.ceil(filteredDepartments.length / itemsPerPage);


    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };
  
    const currentDepartments = filteredDepartments
      .slice(pagesVisited, pagesVisited + itemsPerPage);


    const editDepartment = (id) => {
        navigate('/edit-department?id='+id)
    }

    const addDepartment = () => {
      navigate('/add-department')
    }

    const getDepartments = async () => {
        
        dispatch(show());
        const responseAllDepartments = await ApiService.getDepartments();
        dispatch(fetchDepartmentSuccess(responseAllDepartments));
        dispatch(hide());
    }


    useEffect(() => {
        if(!isFetchingDepartment) {
            dispatch(fetchDepartment());
        }
    }, []);

    useEffect(() => {
        if(isFetchingDepartment) {
            getDepartments();
        }
    }, [isFetchingDepartment]);
    return (
      <div className='flex min-h-screen flex-col'>
        <div className="p-4">
        
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                <Typography variant="h5" color="blue-gray">
                  {pageTitle}
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                  {pageDescription}
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <Button variant="outlined" color="blue-gray" size="sm" onClick={viewAll}>
                  view all
                </Button>
                <Button className="flex items-center gap-3" color="blue" size="sm" onClick={addDepartment}>
                  <UserGroupIcon strokeWidth={2} className="h-4 w-4" /> Add Department
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs value="all" className="w-full md:w-max">
                <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
              <div className="w-full md:w-72">
                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchFor} onChange={searchHandler} />
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll px-0">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                      >
                        {head}{" "}
                        {index !== TABLE_HEAD.length - 1 && (
                          <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentDepartments.map(({ ID, ImageFile, Name, Tereo, Email, Location }, index) => {
                  const isLast = index === departments.length - 1;
                  const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
    
                  return (
                    <tr key={ID}>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {ID}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Avatar src={"/images/departments/" + ImageFile + "?vr=" + randomMix} alt={Name} size="sm" />
                          <div className="flex flex-col">
                            <Typography variant="small" color="blue-gray" className="font-normal">
                              {Name}
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {Tereo}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                            {Email}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                            {Location}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Edit Department">
                          <IconButton variant="text" color="blue-gray" onClick={() => editDepartment(ID)}>
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
                  <ReactPaginate
                      previousLabel={
                        <Button variant="outlined" color="blue-gray" size="sm">
                          Previous
                        </Button>
                      }
                      nextLabel={
                        <Button variant="outlined" color="blue-gray" size="sm">
                          Next
                        </Button>
                      }
                      forcePage={pageNumber}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      pageClassName={"relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"}
                      pageLinkClassName={"absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full h-full flex items-center justify-center"}
                      containerClassName={"flex items-center gap-4 justify-center py-4"}
                      previousLinkClassName={"align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 flex items-center gap-2"}
                      nextLinkClassName={"align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 flex items-center gap-2"}
                      disabledClassName={"pagination-disabled"}
                      activeClassName={"bg-blue-500 text-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"}
                      activeLinkClassName={"absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2  w-full h-full"}
                  />
          </CardFooter>        
        </Card>

        </div>
    </div>
    )
}

export default DepartmentListPage;