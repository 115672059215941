import React from 'react';
import { useSelector } from 'react-redux';
import loadingWheel from "../assets/loading.svg";
import classNames from "classnames";

const LoadingMini = ({classProp}) => {
    const showMiniLoading = useSelector((state) => state.miniLoaderReducer.showMiniFlag);

    return showMiniLoading ? (
        <div className={`flex fixed z-[10000] backdrop-blur-md items-center justify-center rounded-full ${classProp}`}>
          <img src={loadingWheel} alt="" className="w-20 h-20" />
        </div>
      ) : null;
}

export default LoadingMini;