import React, { useState, useEffect } from 'react';

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";

import {
    Card,
    Typography,
    CardBody,
    Avatar,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import { fetchTutorialsByDepartment } from '../store/slices/tutorialsDepartment.slice';

const TABLE_HEAD = ["ID", "StartDate", "EndDate", "Location", "Department", ""];

const TutorialsTabComponent = ({id}) => {

    let baseURL = "http://" + window.location.host;

    if (window.location.origin.startsWith('https://')) {
      baseURL = "https://" + window.location.host;
    }

    const dispatch = useDispatch();

    const currentTutorials = useSelector((state) => state.tutorialsDepartmentReducer.tutorials);


    useEffect(() => {
        dispatch(fetchTutorialsByDepartment(id));
    }, [dispatch]);

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">
                <Card className="h-full w-full">
                    <CardBody className="overflow-x-scroll px-0">   

                    <table className="mt-4 w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                {TABLE_HEAD.map((head, index) => (
                                <th
                                key={head}
                                className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                >
                                    {head}{" "}
                                    {index !== TABLE_HEAD.length - 1 && (
                                    <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                    )}
                                </Typography>
                                </th>
                                ))}
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    currentTutorials.map(({ ID, StartDate, EndDate, Location, Department }, index) => {
                                    
                                    const isLast = index === currentTutorials.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                                <tr key={ID}>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {ID}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">                                                        
                                                        <div className="flex flex-col">
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                            { StartDate }
                                                            </Typography>                                                            
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">                                                            
                                                            { EndDate }
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {Location}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {Department}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Tooltip content="Edit Tutorial">
                                                        <IconButton variant="text" color="blue-gray" onClick={() => editMember(ID)}>
                                                            <PencilIcon className="h-4 w-4" />
                                                        </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                        );
                                    })
                                }
                            </tbody>

                    </table>

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default TutorialsTabComponent;