import React, {useState, useEffect} from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    Typography
 } from "@material-tailwind/react";

const HelpPage = () => {
    const pageTitle = "Help Page";
    const pageDescription = "Basic information on how to use the CMS.";

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

            <Card className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-4 flex items-center justify-between gap-8">
                            <div>
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="px-5">

                    </CardBody>
            </Card>

            </div>
        </div>
    )
}

export default HelpPage;