import React, { Fragment, useState, useEffect} from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {    
    Typography
 } from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";

import ApiService from '../services/api/api.service';

const SelectComponent = ({selected, setSelected}) => {

    const dispatch = useDispatch();

    const [departments, setDepartments] = useState(null);

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }
    
    const getDepartments = async () => {
        dispatch(show());
        try {
            const departmentsResponse = await ApiService.getDepartmentsIDNameTuple();
            setDepartments(departmentsResponse);
        } catch(error) {
            navigate('/login');
        }
        dispatch(hide());
    }

    useEffect(() => {
        getDepartments();
    }, []);
    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                <div className="relative">
                    <Typography className="mb-2" variant="small">
                            Department :
                    </Typography>
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                        {/* <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                        <span className="ml-3 block truncate">{selected?.name}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                    </Listbox.Button>

                    <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {departments?.map((department) => (
                        <Listbox.Option
                            key={department?.id}
                            className={({ active }) =>
                            classNames(
                                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                            }
                            value={department}
                        >
                            {({ selected, active }) => (
                            <>
                                <div className="flex items-center">
                                {/* <img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                                <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                    {department?.name}
                                </span>
                                </div>

                                {selected ? (
                                <span
                                    className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                                ) : null}
                            </>
                            )}
                        </Listbox.Option>
                        ))}
                    </Listbox.Options>
                    </Transition>
                </div>
                </>
            )}
        </Listbox>
    )
}

export default SelectComponent;