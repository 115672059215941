import React from 'react';
import ReactPaginate from "react-paginate";

import { Button } from "@material-tailwind/react";

export const ListPaginate = ({pageNumber, pageCount, changePage}) => {
  return (
    <>
        <ReactPaginate
            previousLabel={
                <Button variant="outlined" color="blue-gray" size="sm">
                Previous
                </Button>
            }
            nextLabel={
                <Button variant="outlined" color="blue-gray" size="sm">
                Next
                </Button>
            }
            forcePage={pageNumber}
            pageCount={pageCount}
            onPageChange={changePage}
            pageClassName={"relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"}
            pageLinkClassName={"absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full h-full flex items-center justify-center"}
            containerClassName={"flex items-center gap-4 justify-center py-4"}
            previousLinkClassName={"align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 flex items-center gap-2"}
            nextLinkClassName={"align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 flex items-center gap-2"}
            disabledClassName={"pagination-disabled"}
            activeClassName={"bg-blue-500 text-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"}
            activeLinkClassName={"absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2  w-full h-full"}
        />
    </>
  )
}
