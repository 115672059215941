import React, { useState, useEffect } from 'react';

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Input,
    Typography,
    Button,
    CardBody,
    Avatar,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import { fetchMembersByDepartment, updateMembersByDepartment } from '../store/slices/memberDepartment.slice';

import ModalMemberDepartmentComponent from '../components/ModalMemberDepartmentComponent';

const TABLE_HEAD = ["ID", "Member", "Position", "Role", "Department", ""];

const MembersTabComponent = ({id}) => {

    let baseURL = "http://" + window.location.host;

    if (window.location.origin.startsWith('https://')) {
      baseURL = "https://" + window.location.host;
    }

    const dispatch = useDispatch();

    const currentMembers = useSelector((state) => state.memberDepartmentReducer.members);
    
    const [showModalEditMember, setShowModalEditMember] = useState(false);
    const [selectedMemberID, setSelectedMemberID] = useState(0);
    const [selectedDepartmentID, setSelectedDepartmentID] = useState(0);
    const [triggerMemberUpdate, setTriggerMemberUpdate] = useState(false);


    const editMember  = (memberID) => {
        setSelectedMemberID(memberID);
        setShowModalEditMember(true);
    }


    useEffect(() => {

        if (triggerMemberUpdate) {
            console.log("updating member");
            dispatch(updateMembersByDepartment({departmentID: selectedDepartmentID, userID: selectedMemberID}));
            setTriggerMemberUpdate(false);
            window.location.href = baseURL + "/edit-department?id=" + id;
        }

    }, [triggerMemberUpdate]);

    useEffect(() => {
        dispatch(fetchMembersByDepartment(id))
    }, [dispatch]);
    
    return (
        <div className='flex min-h-screen flex-col'>

            <ModalMemberDepartmentComponent showModal={showModalEditMember} setShowModal={setShowModalEditMember} selectedMemberID={selectedMemberID} setTriggerMemberUpdate={setTriggerMemberUpdate} setSelectedDepartmentID={setSelectedDepartmentID} />

            <div className="p-4">

            <Card className="h-full w-full">
                    {/* <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                                <Button variant="outlined" color="blue-gray" size="sm">
                                    view all
                                </Button>
                                <Button className="flex items-center gap-3" color="blue" size="sm">
                                    <UserGroupIcon strokeWidth={2} className="h-4 w-4" /> Add Member
                                </Button>
                            </div>
                            <div className="w-full md:w-72">
                                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} />
                            </div>
                        </div>
                    </CardHeader> */}

                    <CardBody className="overflow-x-scroll px-0">

                        <table className="mt-4 w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                {TABLE_HEAD.map((head, index) => (
                                <th
                                key={head}
                                className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                >
                                    {head}{" "}
                                    {index !== TABLE_HEAD.length - 1 && (
                                    <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                    )}
                                </Typography>
                                </th>
                                ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentMembers.map(({ ID, FirstName, LastName, Email, Position, Role, ImageFile, Department }, index) => {
                                    
                                    const isLast = index === currentMembers.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                    const FullName = FirstName + " " + LastName;
                                    const RoleStringValue = Role.includes('500') ? 'Admin' : 'User';

                                    return (
                                                <tr key={ID}>
                                                    <td className={classes}>
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {ID}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                        <Avatar src={"/images/members/" + ImageFile} alt={FullName} size="sm" />
                                                        <div className="flex flex-col">
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                            { FullName }
                                                            </Typography>
                                                            <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal opacity-70"
                                                            >
                                                            {Email}
                                                            </Typography>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">                                                            
                                                            {Position.map((position) => {
                                                                return position + ", "
                                                            })}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {RoleStringValue}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {Department}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Tooltip content="Edit Member">
                                                        <IconButton variant="text" color="blue-gray" onClick={() => editMember(ID)}>
                                                            <PencilIcon className="h-4 w-4" />
                                                        </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>

                    </CardBody>

                </Card>
            </div>
        </div>
    )
}

export default MembersTabComponent;