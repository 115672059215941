import React, {useState, useEffect} from 'react';

import { Breadcrumbs } from "@material-tailwind/react";;

import { useNavigate } from "react-router-dom";


const BreadcrumbsComponent = ({breadcrumbItems}) => {

    const navigate = useNavigate();

    return (
        <Breadcrumbs fullWidth className="mb-6">
                {
                    breadcrumbItems.map((item, index) => {
                        if(index === 0){
                            return  (
                                <span className="opacity-60" onClick={() => navigate("/")}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        >
                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                    </svg>
                                </span>
                            )
                        } else if (index === (breadcrumbItems.length - 1)) {
                            return (<span>{item.label}</span>)
                        } else {
                            return (
                                <span className="opacity-60" onClick={() => navigate(item.url)}>
                                    {item.label}
                                </span>
                            )
                        }
                    })
                }                
        </Breadcrumbs>
    )
}

export default BreadcrumbsComponent;