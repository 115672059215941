import React, {useState, useEffect }  from 'react';

import { useDispatch } from 'react-redux';
import {show, hide} from "../../store/slices/loader.slice";
import { useNavigate } from 'react-router-dom';

import ApiService from '../../services/api/api.service';

const useMembersByTutorialID = (id) => {
    const [members, setMembers] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(show());
        ( async () => {                
            try {
                const getResponse = await ApiService.getMembersByTutorialID(id);
                setMembers(getResponse);
            } catch(error) {
                navigate('/login');
            }
        })();
        dispatch(hide());
    }, []); 

    return { members, setMembers }
}

export default useMembersByTutorialID;