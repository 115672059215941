import React, {useState} from 'react';
import classNames from "classnames";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const SideBar = ({ collapsed }) => {

    const [open, setOpen] = React.useState(0);

    const navigate = useNavigate();
 
    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    const gotTo = (uri) => {
        navigate(uri)
    }

    const accordionMenu = [
        {
            openId: 1,
            headerIcon: PresentationChartBarIcon,
            headerLabel: "Content",
            accordionItemList: [
                {
                    itemLabel: "Manage Departments",
                    itemUri: "/manage-departments"
                },
                {
                    itemLabel: "Manage Members",
                    itemUri: "/manage-members"
                },
                {
                    itemLabel: "Manage Tutorials",
                    itemUri: "/manage-tutorials"
                },
                {
                    itemLabel: "Manage Links",
                    itemUri: "/manage-links"
                },
                // {
                //     itemLabel: "Manage Notifications",
                //     itemUri: "/manage-notifications"
                // },                  
            ]
        },
        {
            openId: 2,
            headerIcon: Cog6ToothIcon,
            headerLabel: "Settings",
            accordionItemList: [
                {
                    itemLabel: "Admin Email",
                    itemUri: "/login"
                }
            ]
        },
    ]

    const listMenu = [
        // {
        //     listItemPrefixIcon: InboxIcon,
        //     listItemLabel: "Inbox",
        //     listItemUri: "/login",
        //     withSuffix: true,
        //     listItemSuffixValue: 33,
        //     listItemSuffixVariant: "ghost",
        //     listItemSuffixColor: "blue-gray",
        //     listItemSuffixClassName: "rounded-full"
        // },
        // {
        //     listItemPrefixIcon: UserCircleIcon,
        //     listItemLabel: "Profile",
        //     listItemUri: "/login",
        //     withSuffix: false,
        //     listItemSuffixValue: 0,
        //     listItemSuffixVariant: "",
        //     listItemSuffixColor: "",
        //     listItemSuffixClassName: ""
        // },
        // {
        //     listItemPrefixIcon: Cog6ToothIcon,
        //     listItemLabel: "Settings",
        //     listItemUri: "/login",
        //     withSuffix: false,
        //     listItemSuffixValue: 0,
        //     listItemSuffixVariant: "",
        //     listItemSuffixColor: "",
        //     listItemSuffixClassName: ""            
        // },
        {
            listItemPrefixIcon: PowerIcon,
            listItemLabel: "Log Out",
            listItemUri: "/login",
            withSuffix: false,
            listItemSuffixValue: 0,
            listItemSuffixVariant: "",
            listItemSuffixColor: "",
            listItemSuffixClassName: ""
        }
    ]

    return (
        <div className="bg-transparent text-zinc-50 z-20">
                <div className="flex flex-col justify-between">
                    <List 
                            className={classNames({
                                    "fixed top-0 p-0 min-w-[256px]": true,
                                    "left-0": !collapsed,
                                    "-left-full": collapsed,
                                    "transition-all duration-300 ease-in-out": true,
                                }
                            )}
                    >
                            <div
                                className={classNames({
                                        "flex items-center border-b border-b-gray-300 h-[73px]": true,
                                        "p-4 justify-between": !collapsed,
                                        "py-4 justify-center": collapsed,
                                    }
                                )}
                            >
                                {!collapsed && <span className="whitespace-nowrap font-semibold text-lg text-light-blue-900">Tuakana CMS</span>}
                            </div>                        

                            {accordionMenu.map(({openId, headerIcon, headerLabel, accordionItemList}, key1) => {
                                return (
                                    <Accordion
                                        open={open === openId}
                                        icon={
                                            <ChevronDownIcon
                                                strokeWidth={2.5}
                                                className={`mx-auto h-4 w-4 transition-transform ${open === openId ? "rotate-180" : ""}`}
                                            />
                                        }
                                        key={key1}
                                    >
                                            <ListItem className="p-0" selected={open === openId}>
                                                <AccordionHeader onClick={() => handleOpen(openId)} className="border-b-0 p-3">
                                                    <ListItemPrefix>
                                                        {
                                                            React.createElement(headerIcon, {
                                                                    className: "h-5 w-5"
                                                                }
                                                            )
                                                        }                                                
                                                    </ListItemPrefix>
                                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                                        {headerLabel}
                                                    </Typography>
                                                </AccordionHeader>
                                            </ListItem>

                                            <AccordionBody className="py-1">
                                                <List className="p-0">
                                                    {
                                                        accordionItemList.map(({itemLabel, itemUri}, key2) => {
                                                            return (
                                                                <ListItem onClick={() => gotTo(itemUri)} key={key2}>
                                                                    <ListItemPrefix>
                                                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                                                    </ListItemPrefix>
                                                                    {itemLabel}
                                                                </ListItem>
                                                            )
                                                        })
                                                    }   
                                                </List>
                                            </AccordionBody>
                                    </Accordion>
                                )
                            })}

                            {
                                listMenu.map(({listItemPrefixIcon, listItemLabel, listItemUri, withSuffix, listItemSuffixValue, listItemSuffixVariant, listItemSuffixColor, listItemSuffixClassName}, key1) => {
                                    return (
                                        <ListItem onClick={() => gotTo(listItemUri)} key={key1}>
                                                <ListItemPrefix>
                                                    {
                                                        React.createElement(listItemPrefixIcon, {
                                                                className: "h-5 w-5"
                                                            }
                                                        )
                                                    }    
                                                </ListItemPrefix>
                                                {listItemLabel}
                                                { withSuffix && 
                                                    <ListItemSuffix>
                                                        <Chip value={listItemSuffixValue} size="sm" variant={listItemSuffixVariant} color={listItemSuffixColor} className={listItemSuffixClassName} />
                                                    </ListItemSuffix>
                                                }                                                
                                        </ListItem>
                                    )
                                })
                            }

                    </List>
            </div>
        </div>
    )
}

export default SideBar;