import React, {useState, useEffect, useRef} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, UserGroupIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Input,
    Typography,
    Button,
    CardBody,
    CardFooter,
    Tabs,
    TabsHeader,
    Tab,
    Alert,
    Avatar,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";
import ApiService from '../services/api/api.service';

import ReactPaginate from "react-paginate";
import BreadcrumbsComponent from '../components/BreadCrumbsComponent';


const PickMemberPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const pageTitle = "Select Member";
    const pageDescription = "Click the check icon to select the member you wish to add to tutorial.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Edit Tutorial", url: "/edit-tutorial?id=" + searchParams.get("id") },
        { label: "Select Member", url: "/" }
    ]

    const TABLE_HEAD = ["Name", "Department", ""];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [openSaveAlert, setOpenSaveAlert] = useState(false);

    const [searchFor, setSearchFor] = useState("");

    const viewAll = () => {
        setSearchFor("");        
    }

    const searchHandler = (e) => {
        setSearchFor(e.target.value);
    }

    const filteredMembers = members.filter((member) => {
        let found = false;
        if(searchFor === ""){
            found = true;
        } else if (member.name.toLowerCase().includes(searchFor.toLocaleLowerCase())) {
            found = true;
        }
        return found;
    })
    
    const randomMix = Math.floor(Math.random() * (100 - 2 + 1)) + 2;

    const handleSelectMember = async (id) => {

        dispatch(show());
        ( async () => {                
            try {
                const getResponse = await ApiService.addMembersToTutorial({
                    selected_members: id
                },searchParams.get("id"));
                const newMembers = members.filter((item) => item.id != id);
                setMembers(newMembers);
            } catch(error) {
                navigate('/login');
            }
        })();        
        dispatch(hide());
        setOpenSaveAlert(true);
        setTimeout(() => {
            navigate('/edit-tutorial?id=' + searchParams.get("id") );               
        }, 1000);
    }

    useEffect(() => {

            dispatch(show());
            ( async () => {                
                try {
                    const getResponse = await ApiService.getMembersForPickMemberForTutorial(searchParams.get("id"));
                    setMembers(getResponse);
                } catch(error) {
                    navigate('/login');
                }
            })();
            dispatch(hide());

    }, []);

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);
    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                        open={openSaveAlert}
                        onClose={() => setOpenSaveAlert(false)}
                        animate={{
                        mount: { y: 0 },
                        unmount: { y: 100 },
                        }}
                    >
                        Selected member has been added to tutorial.
                </Alert>

                <Card className="h-full w-full">

                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                            <div className="w-full md:w-max">
                                <Button variant="outlined" color="blue-gray" size="sm" onClick={viewAll}>
                                    view all
                                </Button>
                            </div>
                            <div className="w-full md:w-72">
                            <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchFor} onChange={searchHandler} />
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="overflow-scroll px-0">


                        <table className="mt-4 w-full min-w-max table-auto text-left">

                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head, index) => (
                                    <th
                                    key={head}
                                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                    >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                    >
                                        {head}{" "}
                                        {index !== TABLE_HEAD.length - 1 && (
                                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                        )}
                                    </Typography>
                                    </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>

                                {filteredMembers.map(({id, name, email, image_file, department}, index) => {
                                    return (
                                            <tr key={id}>

                                                <td className="p-4 border-b border-blue-gray-50">
                                                    <Tooltip content="Select Member">
                                                        <IconButton variant="text" color="green" onClick={() => handleSelectMember(id) }>
                                                            <CheckCircleIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>

                                                <td className="p-4 border-b border-blue-gray-50">
                                                    <div className="flex items-center gap-3">
                                                        <Avatar src={"/images/members/" + image_file + "?vr=89?vr=" + randomMix} alt={name} size="sm" />
                                                        <div className="flex flex-col">
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {name}
                                                            </Typography>
                                                            <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal opacity-70"
                                                            >
                                                                {email}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="p-4 border-b border-blue-gray-50">
                                                    <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {department}
                                                    </Typography>
                                                    </div>
                                                </td>

                                            </tr> 
                                    )
                                })}

                            </tbody>

                        </table>

                    </CardBody>

                </Card>


            </div>
        </div>
    )
}

export default PickMemberPage;