import { configureStore } from '@reduxjs/toolkit'
import LoaderSliceReducer from './slices/loader.slice';
import ProfileSliceReducer from './slices/profile.slice';
import DepartmentSliceReducer from './slices/department.slice';
import ImageProfileSliceReducer from './slices/profileImage.slice';
import MiniLoaderSliceReducer from './slices/miniLoader.slice';
import MemberSliceReducer from './slices/member.slice';
import MemberDepartmentSliceReducer from './slices/memberDepartment.slice';
import TutorialsDepartmentSliceReducer from './slices/tutorialsDepartment.slice';
import TutorialsSliceReducer from './slices/tutorial.slice';
import FetchFlagSliceReducer from './slices/fetchFlag.slice';

const store = configureStore({
  reducer: {
    loaderReducer: LoaderSliceReducer,
    miniLoaderReducer: MiniLoaderSliceReducer,
    profileReducer : ProfileSliceReducer,
    departmentReducer : DepartmentSliceReducer,
    imageProfileReducer : ImageProfileSliceReducer,
    memberReducer: MemberSliceReducer,
    memberDepartmentReducer: MemberDepartmentSliceReducer,
    tutorialsDepartmentReducer: TutorialsDepartmentSliceReducer,
    tutorialsReducer: TutorialsSliceReducer,
    fetchFlagReducer: FetchFlagSliceReducer
  }
})

export default store