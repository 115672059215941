import React, {useState, useEffect, useRef} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";
import { fetchFlagStart, fetchFlagEnd } from "../store/slices/fetchFlag.slice";

import { useNavigate } from "react-router-dom";
import ApiService from '../services/api/api.service';

const withListComponent = (WrappedComponent) => {
  return (props) => {

    const isFetching = useSelector((state) => state.fetchFlagReducer.isFetching);
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState(null);

    const searchRef = useRef();
    const [searchFor, setSearchFor] = useState("");

    const navigate = useNavigate();

    const searchHandler = (e) => {
      setPageNumber(0);
      setSearchFor(e.target.value);
    }

    const editItem = (id) => {
      navigate( props.editURL +'?id='+id )
    }

    const addItem = () => {
      navigate( props.addURL )
    }

    const viewAll = () => {
      setSearchFor("");        
      setPageNumber(0);
      searchRef.current.focus();
    }

    const [pageNumber, setPageNumber] = useState(0);

    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const searchKeyTerm = (dataItem) => {
      let searchKeyFound = false;

      props.filtersArray.map(term => {
        if (dataItem[term]?.toLowerCase().includes(searchFor.toLocaleLowerCase())) {
          searchKeyFound = true;
        }
      });

      return searchKeyFound;
    }

    const filteredList = dataList?.filter((item) => {

      let found = false;
      if(searchFor === ""){
          found = true;
      } else if (searchKeyTerm(item)) {
          found = true;
      }

      return found;
    });

    const itemsPerPage = 5;
    const pagesVisited = pageNumber * itemsPerPage;
    const pageCount = Math.ceil(filteredList?.length / itemsPerPage);
    
    const currentPageList = filteredList?.slice(pagesVisited, pagesVisited + itemsPerPage);

    const listProps = {
      [props.resourceName]: currentPageList,
      changePage: changePage,
      pageCount: pageCount,
      pageNumber: pageNumber,
      searchRef: searchRef,
      searchFor: searchFor,
      searchHandler: searchHandler,
      viewAll: viewAll,
      editItem: editItem,
      addItem: addItem
    }

    useEffect(() => {
        if (!isFetching) {
            dispatch(fetchFlagStart());
        }
    },[]);

    useEffect(() => {

        if (isFetching) {
        
          (async () => {

              dispatch(show());
              const response = await ApiService.protectedGet(props.resourcePath);
              dispatch(fetchFlagEnd());
              setDataList(response.data);
              dispatch(hide());

          })();

        }

    }, [isFetching]);
    return <WrappedComponent {...props} {...listProps} />
  }
}

export default withListComponent;