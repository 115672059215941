import React, {useState, useEffect, useRef} from 'react';
import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserGroupIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Input,
    Typography,
    Button,
    CardBody,
    CardFooter,
    Tabs,
    TabsHeader,
    Tab,
    Avatar,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";
import { useNavigate } from "react-router-dom";
import { fetchTutorials } from '..//store/slices/tutorial.slice';

import ApiService from '../services/api/api.service';

import ReactPaginate from "react-paginate";

import { ListPaginate } from '../components/ListPaginate';

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

const TABS = [
    {
        label: "All",
        value: "all",
        },
        {
        label: "Active",
        value: "active",
        },
        {
        label: "Inactive",
        value: "inactive",
    },
];

const TABLE_HEAD = ["ID", "StartDate", "EndDate", "Location", "Department", ""];

const TutorialListPage = () => {

    const pageTitle = "Manage Tutorials";
    const pageDescription = "Btowse list, add, edit and delete tutorials.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Tutorials", url: "/manage-tutorials" }
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const searchRef = useRef();

    const [pageNumber, setPageNumber] = useState(0);
    const [searchFor, setSearchFor] = useState("");

    const tutorials = useSelector((state) => state.tutorialsReducer.tutorials);

    const viewAll = () => {
        setSearchFor("");        
        setPageNumber(0);
        searchRef.current.focus();
    }

    const searchHandler = (e) => {
        setPageNumber(0);
        setSearchFor(e.target.value);
    }

    const addTutorial = () => {
        navigate('/add-tutorial')
    }

    const filteredTutorials = tutorials.filter((tutorial) => {

        let found = false;
        if(searchFor === ""){
            found = true;
        } else if (tutorial.Details?.toLowerCase().includes(searchFor.toLocaleLowerCase()) || tutorial.Location?.toLowerCase().includes(searchFor.toLocaleLowerCase()) || tutorial.Department?.toLowerCase().includes(searchFor.toLocaleLowerCase())) {
            found = true;
        }

        return found;
    });

    // Pagination Vars and States    
    const itemsPerPage = 5;
    const pagesVisited = pageNumber * itemsPerPage;
    const pageCount = Math.ceil(filteredTutorials.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const currentTutorials = filteredTutorials
    .slice(pagesVisited, pagesVisited + itemsPerPage);

    const editTutorial = (id) => {
        navigate('/edit-tutorial?id='+id)
    }


    useEffect(()=>{
        dispatch(fetchTutorials());
    },[dispatch]);

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Card className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            <Button variant="outlined" color="blue-gray" size="sm" onClick={viewAll}>
                                view all
                            </Button>
                            <Button className="flex items-center gap-3" color="blue" size="sm" onClick={addTutorial}>
                                <UserGroupIcon strokeWidth={2} className="h-4 w-4" /> Add Tutorial
                            </Button>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                            <Tabs value="all" className="w-full md:w-max">
                            <TabsHeader>
                                {TABS.map(({ label, value }) => (
                                <Tab key={value} value={value}>
                                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                                </Tab>
                                ))}
                            </TabsHeader>
                            </Tabs>
                            <div className="w-full md:w-72">
                                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} inputRef={searchRef} value={searchFor} onChange={searchHandler} />
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="overflow-scroll px-0">

                        <table className="mt-4 w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                {TABLE_HEAD.map((head, index) => (
                                <th
                                key={head}
                                className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                >
                                    {head}{" "}
                                    {index !== TABLE_HEAD.length - 1 && (
                                    <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                    )}
                                </Typography>
                                </th>
                                ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentTutorials.map(({ ID, StartDate, EndDate, Details, Location, Department }, index) => {
                                const isLast = index === tutorials.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";                                

                                return (
                                            <tr key={ID}>
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {ID}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex items-center gap-3">
                                                    <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                        { StartDate }
                                                        </Typography>                                                        
                                                    </div>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {EndDate}
                                                    </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {Location}
                                                    </Typography>
                                                    </div>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {Department}
                                                    </Typography>
                                                    </div>
                                                </td>                                                
                                                <td className={classes}>
                                                    <Tooltip content="Edit Tutorial">
                                                    <IconButton variant="text" color="blue-gray" onClick={() => editTutorial(ID)}>
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                    </CardBody>

                    <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
                        <ListPaginate pageNumber={pageNumber} pageCount={pageCount} changePage={changePage} />
                    </CardFooter>                     

                </Card>

            </div>
        </div>
    )
}

export default TutorialListPage;
