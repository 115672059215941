import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from '../../services/api/api.service';
import { show, hide } from '../slices/loader.slice';

const fetchMembersByDepartmentAPI = async (departmentID) => {
    const response = await ApiService.getMembersByDepartment(departmentID);
    return response;
}

const updateMembersByDepartmentAPI = async (departmentID, userID) => {
    const formData = new FormData(); 

    formData.append("userid", userID);
    formData.append("departmentid", departmentID);

    await ApiService.updateMemberDepartment(formData);
}

export const fetchMembersByDepartment = createAsyncThunk('members/fetchMembersByDepartment', async (departmentID, { dispatch }) => {
    dispatch(show()); 
    const data = await fetchMembersByDepartmentAPI(departmentID);
    dispatch(hide()); 
    return data;
});

export const updateMembersByDepartment = createAsyncThunk('members/updateMembersByDepartment', async ({departmentID, userID}, { dispatch }) => {
    dispatch(show()); 
    await updateMembersByDepartmentAPI(departmentID, userID);
    dispatch(hide()); 
    return userID;
});


const MemberDepartmentSlice = createSlice({
    name: 'memberDepartment',
    initialState: {
        members: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMembersByDepartment.fulfilled, (state, action) => {
                state.members = action.payload;
            })
            .addCase(updateMembersByDepartment.fulfilled, (state, action) => {                    
                state.members = state.members.filter(member => member.id !== action.payload);                
            });
    },
})

export default MemberDepartmentSlice.reducer;