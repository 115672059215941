import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from '../../services/api/api.service';
import { show, hide } from '../slices/loader.slice';


const fetchTutorialsByIDAPI = async (tutorialID) => {
    const response = await ApiService.getTutorialByID(tutorialID);
    return response;
}

const fetchTutorialsAPI = async () => {
    const response = await ApiService.getTutorials();
    return response;
}

export const fetchTutorialsByID = createAsyncThunk('tutorials/fetchTutorialByID', async (tutorialID, {dispatch}) => {
    dispatch(show()); 
    const data = await fetchTutorialsByIDAPI(tutorialID);
    dispatch(hide()); 
    return data;
})

export const fetchTutorials = createAsyncThunk('tutorials/fetchTutorials', async (_, {dispatch}) => {
    dispatch(show()); 
    // await new Promise(resolve => setTimeout(resolve, 3000));
    const data = await fetchTutorialsAPI();
    dispatch(hide()); 
    return data;
});

const TutorialsSlice = createSlice({
    name: 'tutorials',
    initialState:{
        tutorials: [],
        tutorialDetails : {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTutorials.fulfilled, (state, action) => {
                state.tutorials = action.payload
            })
            .addCase(fetchTutorialsByID.fulfilled, (state, action) => {
                state.tutorialDetails = action.payload
            });
    }
})

export default TutorialsSlice.reducer;