import React, {useEffect} from 'react'

import withListComponent from './withListComponent';

import { ListPaginate } from '../components/ListPaginate';

import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserGroupIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    Input,
    Typography,
    Button,
    CardBody,
    CardFooter,
    Tabs,
    TabsHeader,
    Tab,    
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

const LinkLIstContainer = ({ TABLE_HEAD, linkList, changePage, pageCount, pageNumber, searchRef, searchFor, searchHandler, viewAll, editItem, addItem }) => {

    const pageTitle = "Manage Links";
    const pageDescription = "Browse list, add, edit and delete links.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Links", url: "/manage-links" }
    ]

    const TABS = [
        {
            label: "All",
            value: "all",
            },
            {
            label: "Active",
            value: "active",
            },
            {
            label: "Inactive",
            value: "inactive",
        },
    ];
 
    return linkList ? (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">


                <Card className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-8 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            <Button variant="outlined" color="blue-gray" size="sm" onClick={viewAll}>
                                view all
                            </Button>
                            <Button className="flex items-center gap-3" color="blue" size="sm" onClick={addItem}>
                                <UserGroupIcon strokeWidth={2} className="h-4 w-4" /> Add Tutorial
                            </Button>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                            <Tabs value="all" className="w-full md:w-max">
                                <TabsHeader>
                                    {TABS.map(({ label, value }) => (
                                    <Tab key={value} value={value}>
                                        &nbsp;&nbsp;{label}&nbsp;&nbsp;
                                    </Tab>
                                    ))}
                                </TabsHeader>
                            </Tabs>
                            <div className="w-full md:w-72">
                                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} inputRef={searchRef} value={searchFor} onChange={searchHandler} />
                            </div>
                        </div>
                    </CardHeader>


                    <CardBody className="overflow-scroll px-0">

                        <table className="mt-4 w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head, index) => (
                                        <th
                                        key={head}
                                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                        >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                        >
                                            {head}{" "}
                                            {index !== TABLE_HEAD.length - 1 && (
                                            <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                            )}
                                        </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {linkList.map( ({id, name, department_name}, index) => {
                                    const classes = "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={id}>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {id}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {name}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {department_name}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                    <Tooltip content="Edit Item">
                                                    <IconButton variant="text" color="blue-gray" onClick={() => editItem(id)}>
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                    </Tooltip>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </CardBody>

                    <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
                        <ListPaginate pageNumber={pageNumber} pageCount={pageCount} changePage={changePage} />
                    </CardFooter>

                </Card>

            </div>
        </div>
        ) : (<>...loading</>)
}

export default withListComponent(LinkLIstContainer);