import React, {useState, useEffect, useRef }  from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { UsersIcon, CalendarDaysIcon, PencilIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Typography,
    Avatar, 
    Button,
    Alert,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Textarea  
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";

import ApiService from '../services/api/api.service';

import LoadingMiniCustom from '../components/LoadingMiniCustom';

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import SelectComponent from '../components/SelectComponent';
import { fetchDepartment, fetchDepartmentSuccess } from '../store/slices/department.slice';

const Icon = ({id, open})  => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const AddTutorialPage = () => {

    const pageTitle = "Add Tutorial";
    const pageDescription = "Add tutorial data.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Tutorials", url: "/manage-tutorials" },
        { label: "Add Tutorial", url: "/" },
    ]

    const mapFileRef = useRef();
    const campusMapFileRef = useRef();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [openAccordion, setOpenAccordion] = React.useState(1);
    const handleOpenAccordion = (value) => setOpenAccordion(openAccordion === value ? 0 : value);

    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();

    const todayFormatted = `${year}-${month}-${date}`;
    
    const [startDateValue, setStartDateValue] = useState(dayjs());
    const [endDateValue, setEndDateValue] = useState(dayjs());

    const [details, setDetails] = useState("");
    const [location, setLocation] = useState("");
    const [roomMapVideo, setRoomMapVideo] = useState("");

    const [roomMapImage, setRoomMapImage] = useState("/images/tutorials/default_mapimage.png");
    const [roomMapImageFileName, setRoomMapImageFileName] = useState("default_mapimage.png");    

    const [campusMap, setCampusMap] = useState("/images/tutorials/pdf_default.png");
    const [campusMapFileName, setCampusMapFileName] = useState("pdf_default.png");

    const [showLoadingImageFile, setShowLoadingImageFile] = useState(false);
    const [showLoadingMapFile, setShowLoadingMapFile] = useState(false);
    const [showLoadingCampusMap, setShowLoadingCampusMap] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState({id: '2', name: 'School of Chemical Sciences'});

    const [openSaveAlert, setOpenSaveAlert] = useState(false);

    const currentDepartments = useSelector((state) => state.departmentReducer.departments);
    const isFetchingDepartment = useSelector((state) => state.departmentReducer.isFetchingDepartment);

    const convertInputTime = (dateTimeInput) => {
        const dateObject = new Date(dateTimeInput);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");

        const hours = String(dateObject.getUTCHours()).padStart(2, "0");
        const minutes = String(dateObject.getUTCMinutes()).padStart(2, "0");

        const outputDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        return outputDateTime;
    }

    const handleMapFileChangeTrigger = () => {        
        if(mapFileRef.current) {
            mapFileRef.current.click();
        }
    }

    const handleCampusMapFileChangeTrigger = () => {        
        if(campusMapFileRef.current) {
            campusMapFileRef.current.click();
        }
    }

    const handleMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);

        setShowLoadingMapFile(true);

        try {
            const uploadTutorialLocationImageFileResult = await ApiService.uploadTutorialLocationImageFile(formData);
            setRoomMapImageFileName(uploadTutorialLocationImageFileResult);
            setRoomMapImage(URL.createObjectURL(e.target.files[0]));
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingMapFile(false);
    }    

    const handleCampusMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("campusMapFile", e.target.files[0]);

        setShowLoadingCampusMap(true);

        try {
            const uploadTutorialCampusMapFileResult = await ApiService.uploadTutorialCampusMapFile(formData);            
            setCampusMapFileName(uploadTutorialCampusMapFileResult);
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingCampusMap(false);
    }

    const handleAddTutorial = async () => {
        const formData = new FormData();

        formData.append("start_date", dayjs(startDateValue).format('YYYY-MM-DD HH:mm'));
        formData.append("end_date", dayjs(endDateValue).format('YYYY-MM-DD HH:mm'));
        formData.append("details", details);
        formData.append("location", location);
        formData.append("department_id", selectedDepartment.id);

        formData.append("room_map_video", roomMapVideo);
        formData.append("room_map_image_file", roomMapImageFileName);
        formData.append("campus_map_file", campusMapFileName);
        
        dispatch(show());
        try {
            const addTutorialResult = await ApiService.addTutorial(formData);
            console.log(addTutorialResult);
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide());  
        setOpenSaveAlert(true);
        setTimeout(() => {
            navigate('/manage-tutorials');               
        }, 2000);
    }

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);

    useEffect(() => {
        if(!isFetchingDepartment) {
            dispatch(fetchDepartment());
        }
    }, []);

    useEffect(() => {
        if(isFetchingDepartment) {
                (async () => {
                    try {
                        const getDepartmentsResponse = await ApiService.getDepartmentsIDNameTuple();
                        dispatch(fetchDepartmentSuccess(getDepartmentsResponse));
                    } catch(error) {
                        navigate('/login');
                    }
                })();            
        }
    }, [isFetchingDepartment]);
    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

            <Alert
                    open={openSaveAlert}
                    onClose={() => setOpenSaveAlert(false)}
                    animate={{
                    mount: { y: 0 },
                    unmount: { y: 100 },
                    }}
                >
                    Tutorial has been added.
            </Alert>

            <Card className="h-full w-full">


                <CardHeader floated={false} shadow={false} className="rounded-none">
                    <div className="mb-4 flex items-center justify-between gap-8">
                        <div>
                            <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                            <Typography variant="h5" color="blue-gray">
                                {pageTitle}
                            </Typography>
                            <Typography color="gray" className="mt-1 font-normal">
                                {pageDescription}
                            </Typography>                                
                        </div>
                        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                        </div>
                    </div>
                </CardHeader>

                
                <CardBody className="px-5">

                    <div>

                        <div className="mb-5">
                            <SelectComponent selected={selectedDepartment} setSelected={setSelectedDepartment} />
                        </div>

                        <Accordion open={openAccordion === 1} icon={<Icon id={1} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 
                
                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(1)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Dates
                            </AccordionHeader>

                            <AccordionBody className="flex">
                                    
                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDateValue}
                                                onChange={(newValue) => setStartDateValue(newValue)}
                                            />
                                        </DemoContainer>

                                    </LocalizationProvider>
                                </div>

                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDateValue}
                                                onChange={(newValue) => setEndDateValue(newValue)}
                                            />
                                        </DemoContainer>

                                    </LocalizationProvider>
                                </div>

                            </AccordionBody>

                        </Accordion>

                        <Accordion open={openAccordion === 2} icon={<Icon id={2} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 

                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(2)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 2 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Tutorial Details
                            </AccordionHeader>

                            <AccordionBody>
                                <div>
                                    <Textarea 
                                        color="blue" 
                                        label="Content"
                                        id="details"
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                    />
                                </div>                                
                            </AccordionBody>

                        </Accordion>

                        <Accordion open={openAccordion === 3} icon={<Icon id={3} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 
                                
                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(3)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 3 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Location & Maps
                            </AccordionHeader>

                            <AccordionBody>
                                <div className="mb-8">
                                    <Input
                                        type="text"
                                        size="md" 
                                        label="Location"
                                        id="location"
                                        autoComplete="off"
                                        required
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <Input 
                                        type="text" 
                                        size="md" 
                                        label="Room Location Video"
                                        id="roomMapVideo"
                                        autoComplete="off"                                                    
                                        value={roomMapVideo}
                                        onChange={(e) => setRoomMapVideo(e.target.value)}
                                    />
                                </div>
                                <div className="mt-3 mb-8">
                                    Copy and paste a youtube video embeded content markup. See <a href="https://support.google.com/youtube/answer/171780?hl=en" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank">youtube video embed share</a>.
                                </div>
                                <div className="flex">
                                    <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                            <div className="relative h-[110px] w-[110px]">
                                                <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingMapFile} />
                                                <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleMapFileChangeTrigger}>
                                                    <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                </div>
                                                <Avatar src={roomMapImage} alt="avatar" size="xxl" variant="rounded" />
                                                <input type="file" className="hidden" onChange={handleMapFileChange} ref={mapFileRef} />
                                            </div>
                                            <div className="font-bold">
                                                Room Location Image
                                            </div>
                                            <div className="mx-4 text-center">
                                                Upload a photo with a square dimension not less than 200px width by 200px height. Click the image above to change.
                                            </div>
                                    </div>
                                    <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                            <div className="relative h-[110px] w-[110px]">
                                                <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingCampusMap} />
                                                <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleCampusMapFileChangeTrigger}>
                                                    <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                </div>
                                                <Avatar src={campusMap} alt="avatar" size="xxl" variant="rounded" />
                                                <input type="file" className="hidden" onChange={handleCampusMapFileChange} ref={campusMapFileRef} />
                                            </div> 
                                            <div className="font-bold">
                                                Campus Map (pdf)
                                            </div>
                                            <div className="mx-4 text-center">
                                                Upload a pdf file otherwise this will default to the official campus map link.
                                            </div>
                                    </div>
                                </div>                            
                            </AccordionBody>

                        </Accordion>

                        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                                    <Button variant="gradient" className="flex items-center gap-3"  type="submit" onClick={handleAddTutorial}>
                                                    <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="h-5 w-5"
                                                        >
                                                        <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                                                    </svg>                                                    
                                                    Add Tutorial
                                    </Button>
                        </div>

                    </div>

                </CardBody>


                

            </Card>

            </div>
        </div>
)}

export default AddTutorialPage;