import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Dialog,
    DialogHeader,
    Typography,
    IconButton,
    DialogBody,
    DialogFooter,
    Button,
} from '@material-tailwind/react';
import { XMarkIcon } from "@heroicons/react/24/outline";

import SelectComponent from "../components/SelectComponent";

import { useDispatch, useSelector } from 'react-redux';
import { show, hide } from '../store/slices/loader.slice';
import { updateMembersByDepartment } from '../store/slices/memberDepartment.slice';

import ApiService from '../services/api/api.service';

const ModalMemberDepartmentComponent = ({showModal, setShowModal, selectedMemberID, setTriggerMemberUpdate, setSelectedDepartmentID}) => {

    const handleCloseDialog = () => {
        setShowModal(false);
    }

    const dispatch = useDispatch();

    const [selectDepartment, setSelectDepartment] = useState({id: '2', name: 'School of Chemical Sciences'});
    const [selectedMember, setSelectedMember] = useState(null);

    const getUserProfile = async () => {
        const formData = new FormData(); 

        formData.append("userid", selectedMemberID);

        if (selectedMemberID !== 0) {
            dispatch(show());
            try {
                const getUserProfileResponse = await ApiService.getUserProfile(formData);
                setSelectedMember(getUserProfileResponse);
                setSelectDepartment({id: getUserProfileResponse?.department_id, name: getUserProfileResponse?.department_name});
            } catch(error) {
                console.log(error);
            }
            dispatch(hide());
        }
    }

    const saveMemberUpdate = async () => {
        setTriggerMemberUpdate(true);
        // dispatch(updateMembersByDepartment({departmentID: selectDepartment.id, userID: selectedMemberID}));
        setSelectedDepartmentID(selectDepartment.id)
        handleCloseDialog();
    }

    useEffect(() => {
        getUserProfile();
    }, [selectedMemberID]);
    return (
        <Dialog size="md" open={showModal} handler={handleCloseDialog}>
            <DialogHeader className="justify-between">
            <Typography variant="h5" color="blue-gray" className="hyphens-manual">
                Change Department
            </Typography>
            <IconButton
                color="blue-gray"
                size="sm"
                variant="text"
                onClick={handleCloseDialog}
            >
                <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
            </DialogHeader>
            <DialogBody className="pr-2">

            <div className="font-bold text-lg">
                {selectedMember?.first_name + " " + selectedMember?.last_name }
            </div>

            <div className="mt-2">
                <SelectComponent selected={selectDepartment} setSelected={setSelectDepartment} />
            </div>


            </DialogBody>
            <DialogFooter className="border-t border-blue-gray-50 justify-start">
                <Button variant="gradient" className="flex items-center gap-3" type="submit" onClick={saveMemberUpdate}>
                    <svg
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                        <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                    </svg>                                                   
                    Update
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export default ModalMemberDepartmentComponent;