import React, {useState, useEffect, useRef }  from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { UsersIcon, CalendarDaysIcon, PencilIcon } from "@heroicons/react/24/solid";
import { 
        Card, CardHeader, CardBody,
        Accordion, AccordionHeader, AccordionBody,
        Tabs, TabsHeader, TabsBody, Tab, TabPanel,        
        Input, Typography,Avatar, Button, Alert
 } from "@material-tailwind/react";

import { useDispatch } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";

import LoadingMiniCustom from '../components/LoadingMiniCustom';

import ApiService from '../services/api/api.service';

import MembersTabComponent from '../components/MembersTabComponent';
import TutorialsTabComponent from '../components/TutorialsTabComponent';

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

const Icon = ({id, open})  => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const EditDepartmentPage = () => {

    const pageTitle = "Edit Department";
    const pageDescription = "Update selected department.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Departments", url: "/manage-departments" },
        { label: "Edit Department", url: "/" },
    ]

    const navigate = useNavigate();

    const imageFileRef = useRef();
    const mapFileRef = useRef();
    const campusMapFileRef = useRef();

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [tereo, setTereo] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [topic, setTopic] = useState("");
    const [campusMap, setCampusMap] = useState("");
    const [roomMapImage, setRoomMapImage] = useState("");
    const [roomMapVideo, setRoomMapVideo] = useState("");
    const [imageFile, setImageFile] = useState("");
    const [showLoadingImageFile, setShowLoadingImageFile] = useState(false);
    const [showLoadingMapFile, setShowLoadingMapFile] = useState(false);
    const [showLoadingCampusMap, setShowLoadingCampusMap] = useState(false);

    const [openSaveAlert, setOpenSaveAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [openDeleteAlert, setOpenDeleteAlert]  = useState(false);
    const [deleteAlertMessage, setDeleteAlertMessage] = useState("");

    const [openAccordion, setOpenAccordion] = React.useState(1);

    const handleOpenAccordion = (value) => setOpenAccordion(openAccordion === value ? 0 : value);
    
    const getDepartments = async () => {
        
        dispatch(show());

        const responseAllDepartment = await ApiService.getDepartmentsByID(searchParams.get("id"));
        
        setName(responseAllDepartment.Name);
        setTereo(responseAllDepartment.Tereo);
        setEmail(responseAllDepartment.Email);
        setLocation(responseAllDepartment.Location);
        setTopic(responseAllDepartment.Topic);
        setCampusMap("/images/departments/pdf_default.png");
        setRoomMapImage("/images/departments/" + responseAllDepartment.RoomMapImage);
        setRoomMapVideo(responseAllDepartment.RoomMapVideo);
        setImageFile("/images/departments/" + responseAllDepartment.ImageFile);

        dispatch(hide());
    }

    const handleImageFileChangeTrigger = () => {        
        if(imageFileRef.current) {
            imageFileRef.current.click();
        }
    }

    const handleMapFileChangeTrigger = () => {        
        if(mapFileRef.current) {
            mapFileRef.current.click();
        }
    }

    const handleCampusMapFileChangeTrigger = () => {        
        if(campusMapFileRef.current) {
            campusMapFileRef.current.click();
        }
    }

    const handleImageFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);
        formData.append("departmentID", searchParams.get("id"));

        setShowLoadingImageFile(true);

        try {
            const updateDepartmentImageFileResult = await ApiService.updateDepartmentImageFile(formData);
            setImageFile(URL.createObjectURL(e.target.files[0]));
        } catch(error) {
            navigate('/login');
        }        

        setShowLoadingImageFile(false);
    }

    const handleMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);
        formData.append("departmentID", searchParams.get("id"));

        setShowLoadingMapFile(true);

        try {
            const updateMemberImageProfileResult = await ApiService.updateDepartmentMapFile(formData);
            setRoomMapImage(URL.createObjectURL(e.target.files[0]));
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingMapFile(false);
    }

    const handleCampusMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);
        formData.append("departmentID", searchParams.get("id"));

        setShowLoadingCampusMap(true);

        try {
            const updateMemberImageProfileResult = await ApiService.updateDepartmentCampusMapFile(formData);            
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingCampusMap(false);
    }

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleDeleteDepartment = async () => {

        const formData = new FormData();

        formData.append("departmentID", searchParams.get("id"));

        dispatch(show());
        try {
            const deleteDepartmentResult = await ApiService.deleteDepartment(formData);            
        } catch(error) {
            if (error.response.data == "members" && error.response.status == 406) {
                dispatch(hide());
                setDeleteAlertMessage("Members dependent to the department you are about to delete still exists!");
                setOpenDeleteAlert(true);
                const Tout = await timeout(3000);
                navigate('/manage-departments');
                return null;
            }
            if (error.response.data == "tutorials" && error.response.status == 406) {
                dispatch(hide());
                setDeleteAlertMessage("Tutorials dependent to the department you are about to delete still exists!");
                setOpenDeleteAlert(true);
                const Tout = await timeout(3000);
                navigate('/manage-departments');                
                return null;
            }            
            navigate('/login');
        }        
        dispatch(hide());
        setAlertMessage("Department succesfully deleted!")
        setOpenSaveAlert(true);
        const Tout = await timeout(2000);
        navigate('/manage-departments');
        return null;
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("departmentID", searchParams.get("id"));

        formData.append("name", name);
        formData.append("tereo", tereo);
        formData.append("email", email);
        formData.append("location", location);
        formData.append("topic", topic);
        formData.append("room_map_video", roomMapVideo);

        dispatch(show());
        try {
            const updateDepartmentResult = await ApiService.updateDepartment(formData);
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide());
        setAlertMessage(name + " has been updated!");
        setOpenSaveAlert(true);
        setTimeout(() => {
            navigate('/manage-departments');               
        }, 2000);
    }


    useEffect(() => {
        getDepartments();            
    }, []);

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                    open={openSaveAlert}
                    onClose={() => setOpenSaveAlert(false)}
                    animate={{
                    mount: { y: 0 },
                    unmount: { y: 100 },
                    }}
                    color="green"
                >
                    {alertMessage}                    
                </Alert>

                <Alert
                    open={openDeleteAlert}
                    onClose={() => setOpenDeleteAlert(false)}
                    animate={{
                    mount: { y: 0 },
                    unmount: { y: 100 },
                    }}
                    color="red"
                >
                    {deleteAlertMessage}
                </Alert>                

                <Card className="h-full w-full">

                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-4 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>                                
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="px-5">

                        <div className='flex justify-between'>

                            <div className='shrink-0 w-56 border-r-2 border-solids border-gray-300 flex items-center flex-col px-2'>
                                <div className="relative">
                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingImageFile} />
                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleImageFileChangeTrigger}>
                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                    </div>
                                    <Avatar src={imageFile} alt="avatar" size="xxl" variant="rounded" />
                                    <input type="file" className="hidden" onChange={handleImageFileChange} ref={imageFileRef} />
                                </div>
                                <div className="pt-4 text-center">
                                    Upload a photo with dimensions 800px width by 450px height. Click the image above to change.
                                </div>
                            </div>

                            <div className='flex-1 pl-4'>
                                <form onSubmit={(e) => handleOnSubmit(e)}>


                                    <Accordion open={openAccordion === 1} icon={<Icon id={1} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                                        <AccordionHeader 
                                            onClick={() => handleOpenAccordion(1)}
                                            className={`border-b-0 transition-colors ${
                                                openAccordion === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                                            }`}
                                        >
                                            Main
                                        </AccordionHeader>
                                        <AccordionBody className="flex">

                                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">

                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Name"
                                                        id="name"
                                                        autoComplete="off"
                                                        required
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />

                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Tereo"
                                                        id="tereo"
                                                        autoComplete="off"
                                                        required
                                                        value={tereo}
                                                        onChange={(e) => setTereo(e.target.value)}
                                                    />

                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Topic"
                                                        id="topic"
                                                        autoComplete="off"
                                                        required
                                                        value={topic}
                                                        onChange={(e) => setTopic(e.target.value)}
                                                    />

                                                    </div>

                                                    <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">

                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Email"
                                                        id="email"
                                                        autoComplete="off"
                                                        required
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Location"
                                                        id="location"
                                                        autoComplete="off"
                                                        required
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                    />

                                                </div>

                                        </AccordionBody>
                                    </Accordion>

                                    <Accordion open={openAccordion === 2} icon={<Icon id={2} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                                        <AccordionHeader 
                                            onClick={() => handleOpenAccordion(2)}
                                            className={`border-b-0 transition-colors ${
                                                openAccordion === 2 ? "text-blue-500 hover:!text-blue-700" : ""
                                            }`}
                                        >
                                            Location Maps
                                        </AccordionHeader>
                                        <AccordionBody>
                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Room Location Video"
                                                        id="roomMapVideo"
                                                        autoComplete="off"                                                    
                                                        value={roomMapVideo}
                                                        onChange={(e) => setRoomMapVideo(e.target.value)}
                                                    />
                                                    <div className="mt-3 mb-8">
                                                        Copy and paste a youtube video embeded content markup. See <a href="https://support.google.com/youtube/answer/171780?hl=en" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank">youtube video embed share</a>.
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                                                <div className="relative h-[110px] w-[110px]">
                                                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingMapFile} />
                                                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleMapFileChangeTrigger}>
                                                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                                    </div>
                                                                    <Avatar src={roomMapImage} alt="avatar" size="xxl" variant="rounded" />
                                                                    <input type="file" className="hidden" onChange={handleMapFileChange} ref={mapFileRef} />
                                                                </div>
                                                                <div className="font-bold">
                                                                    Room Location Image
                                                                </div>
                                                                <div className="mx-4 text-center">
                                                                    Upload a photo with a square dimension not less than 200px width by 200px height. Click the image above to change.
                                                                </div>
                                                        </div>
                                                        <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                                                <div className="relative h-[110px] w-[110px]">
                                                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingCampusMap} />
                                                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleCampusMapFileChangeTrigger}>
                                                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                                    </div>
                                                                    <Avatar src={campusMap} alt="avatar" size="xxl" variant="rounded" />
                                                                    <input type="file" className="hidden" onChange={handleCampusMapFileChange} ref={campusMapFileRef} />
                                                                </div> 
                                                                <div className="font-bold">
                                                                    Campus Map (pdf)
                                                                </div>
                                                                <div className="mx-4 text-center">
                                                                    Upload a pdf file otherwise this will default to the official campus map link.
                                                                </div>
                                                        </div>
                                                    </div>
                                        </AccordionBody>
                                    </Accordion>

                                    <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                                                <Button variant="gradient" className="flex items-center gap-3"  type="submit">
                                                    <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="h-5 w-5"
                                                        >
                                                        <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                                                    </svg>                                                    
                                                    Update Department
                                                </Button>

                                                <Button variant="gradient" className="flex items-center gap-3"  color="red" onClick={handleDeleteDepartment}>
                                                    <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            className="h-5 w-5"
                                                        >
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path d="M4 8h16v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8zm3-3V3a1 1 0 011-1h8a1 1 0 011 1v2h5v2H2V5h5zm2-1v1h6V4H9zm0 8v6h2v-6H9zm4 0v6h2v-6h-2z" />
                                                    </svg>                                                    
                                                    Delete Department
                                                </Button>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div className="mt-5">
                            <Tabs value="members" id="custom-animation">
                                <TabsHeader>
                                    <Tab value="members">
                                        <div className="flex items-center gap-2">
                                            {React.createElement(UsersIcon, { className: "w-5 h-5" })}
                                            Members
                                        </div>
                                    </Tab>
                                    <Tab value="tutorials">
                                        <div className="flex items-center gap-2">
                                            {React.createElement(CalendarDaysIcon, { className: "w-5 h-5" })}
                                            Tutorials
                                        </div>                                        
                                    </Tab>
                                    {/* <Tab value="news">
                                        <div className="flex items-center gap-2">
                                            {React.createElement(NewspaperIcon, { className: "w-5 h-5" })}
                                            News & Events
                                        </div>                                        
                                    </Tab>                                     */}
                                </TabsHeader>
                                <TabsBody
                                        animate={{
                                            initial: { y: 250 },
                                            mount: { y: 0 },
                                            unmount: { y: 250 },
                                        }}
                                    >
                                    <TabPanel value="members">
                                        <MembersTabComponent id={searchParams.get("id")} />
                                    </TabPanel>

                                    <TabPanel value="tutorials">
                                        <TutorialsTabComponent id={searchParams.get("id")} />
                                    </TabPanel>

                                    {/* <TabPanel value="news">
                                        <TutorialsTabComponent id={searchParams.get("id")} />
                                    </TabPanel> */}
                                </TabsBody>
                            </Tabs>
                        </div>

                    </CardBody>

                </Card>

            </div>
        </div>
    )
}

export default EditDepartmentPage;
