import React, {useCallback} from 'react'
import { Routes, Route } from 'react-router-dom';
import LayoutNoHeader from './components/LayoutNoHeader';
import Layout from './components/Layout';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MyProfilePage from './pages/MyProfilePage';
import HelpPage from './pages/HelpPage';

import MemberListPage from './pages/MemberListPage';
import EditMemberPage from './pages/EditMemberPage';
import AddMemberPage from './pages/AddMemberPage';

import DepartmentListPage from './pages/DepartmentListPage';
import EditDepartmentPage from './pages/EditDepartmentPage';
import AddDepartmentPage from './pages/AddDepartmentPage';

import TutorialListPage from './pages/TutorialListPage';
import EditTutorialPage from './pages/EditTutorialPage';
import AddTutorialPage from './pages/AddTutorialPage';

import LinkListPage from './pages/LinkListPage';
import EditLinkPage from './pages/EditLinkPage';
import AddLinkPage from './pages/AddLinkPage';

import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';

import PickMemberPage from './pages/PickMemberPage';

import './App.css';

const App = () => {
    return (
      <Routes>         
         <Route path="/login" element={<LayoutNoHeader />}>
            <Route path="/login" element={<LoginPage />} />  
         </Route>
         <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={["300"]} />}>
               <Route path="/" element={<Layout />}>
                  <Route path="/" element={<MyProfilePage />} />
                  <Route path="/manage-departments" element={<DepartmentListPage />} />
                  <Route path="/manage-members" element={<MemberListPage />} />
                  <Route path="/manage-tutorials" element={<TutorialListPage />} />
                  <Route path="/manage-links" element={<LinkListPage />} />
                  <Route path="/edit-link" element={<EditLinkPage />} />
                  <Route path="/edit-department" element={<EditDepartmentPage />} />
                  <Route path="/edit-member" element={<EditMemberPage />} />                  
                  <Route path="/pick-member" element={<PickMemberPage />} />                  
                  <Route path="/edit-tutorial" element={<EditTutorialPage />} />
                  <Route path="/add-link" element={<AddLinkPage />} />
                  <Route path="/add-tutorial" element={<AddTutorialPage />} />
                  <Route path="/add-department" element={<AddDepartmentPage />} />
                  <Route path="/add-member" element={<AddMemberPage />} />
                  <Route path="/help" element={<HelpPage />} />
                  <Route path="/homepage" element={<HomePage />} />
               </Route>
            </Route>
         </Route>

      </Routes>
    )
}

export default App;