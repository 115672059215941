import axios from '../helper/axios.helper';

class APIService {
    configNoToken() {
        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    configWithCookies() {
        const options = {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
                'Content-Type' : 'multipart/form-data',
                'Accept' : 'application/json'
            }
        }

        return options;
    }

    configWithCookiesJson() {
        const options = {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            }
        }

        return options;
    }

    async protectedGet(resourcePath) {
        try {
            const result = await axios.get(
                resourcePath,
                this.configWithCookies()
            )
            return result;
        } catch(error) {            
            throw error;
        }
    }

    async protectedPost(resourcePath, data={}) {
        try {
            console.log(data);
            const result = await axios.post(
                resourcePath,
                data,
                this.configWithCookiesJson()
            )
            return result;
        } catch(error) {            
            throw error;
        }
    }

    async protectedDelete(resourcePath) {
        try {

            const result = await axios.delete(
                resourcePath,
                this.configWithCookiesJson()
            )
            return result;
        } catch(error) {            
            throw error;
        }
    }

    async protectedUpdate(resourcePath, formData={}) {
        try {

            const result = await axios.put(
                resourcePath,
                formData,
                this.configWithCookiesJson()
            )
            return result;
        } catch(error) {            
            throw error;
        }
    }

    async updateMemberImageProfile(formData) {

        try {

            const result = await axios.post(
                "update-member-image-profile",
                formData,
                this.configWithCookies()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }

    }

    async updateDepartmentImageFile(formData) {
        try {

            const result = await axios.post(
                "update-department-image-file",
                formData,
                this.configWithCookies()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async updateDepartmentMapFile(formData) {
        try {

            const result = await axios.post(
                "update-department-map-file",
                formData,
                this.configWithCookies()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async getMembersForPickMemberForTutorial(id) {
        try {
            const result = await axios.get(
                "pick-member-for-tutorial?id=" + id,
                this.configWithCookies()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async getMembersByTutorialID(id) {
        try {
            const result = await axios.get(
                "get-members-by-tutorialid?id=" + id,
                this.configWithCookiesJson()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async addMembersToTutorial(formData, id) {
        try {

            const result = await axios.post(
                "add-members-to-tutorial/" + id,
                formData,
                this.configWithCookiesJson()
            )
            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async updateDepartmentCampusMapFile(formData) {
        try {

            const result = await axios.post(
                "update-department-campusmap-file",
                formData,
                this.configWithCookies()
            );            

            return result.data;
        } catch(error) {            
            throw error;
        }
    }

    async updateMemberDepartment(formData) {

        try {
            const response = axios.post(
                "update-member-department",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }

    }

    async addMemberProfile(formData) {
        try {
            const response = axios.post(
                "add-member-profile",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async updateMemberProfile(formData) {

        try {
            const response = axios.post(
                "update-member-profile",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }

    }

    async logout() {
        const response = await axios.post('logoutuser',
            JSON.stringify({ "email" : "test", "password": "test" }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        return response;
    }

    async getUserProfile(formData) {

        try {
            const result = await axios.post(
                "get-member-profile",
                formData,
                this.configWithCookies()
            );
            return result.data;
        } catch(error) {
            throw error;
        }

    }

    async getDepartmentsIDNameTuple() {
        try {
            let options = [];
            const result = await axios.get('departments');
            result.data.forEach((item) => {
                options.push({id: item.ID.toString(), name: item.Name});
            });
            return options;
        } catch(error) {
            throw error;
        }
    }

    async getDepartments() {
        try {
            const result = await axios.get('departments');
            return result.data;
        } catch(error) {
            throw error;
        }
    }


    async getDepartmentsByID(id) {
        try {
            const result = await axios.get('get-department-byid/' + id);
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async getLinkByID(id) {
        try {
            const result = await axios.get('get-link-byid/' + id);
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async updateDepartment(formData) {

        try {
            const response = await axios.post(
                "update-department",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async uploadTutorialLocationImageFile(formData) {

        try {
            const response = await axios.post(
                "upload-tutorial-location-image-file",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async uploadTutorialCampusMapFile(formData) {

        try {
            const response = await axios.post(
                "upload-tutorial-campus-map-file",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async addTutorial(formData) {
        try {
            const response = await axios.post(
                "add-tutorial",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async addDepartment(formData) {
        try {
            const response = await axios.post(
                "add-department",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async deleteDepartment(formData) {
        try {
            const response = await axios.post(
                "delete-department",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }
    

    async deleteMember(formData) {
        try {
            const response = await axios.post(
                "delete-member",
                formData,
                this.configWithCookies()
            );
            return response.data;
        } catch(error) {
            throw error;
        }
    }

    async getMembers() {
        try {
            const result = await axios.get('list-members');
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async getMembersByDepartment(id) {
        try {
            const result = await axios.get('list-members-by-department/' + id);
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async getTutorialsByDepartments(id) {
        try{
            const result = await axios.get("list-tutorials-by-department/" + id);
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async getTutorials() {
        try{
            const result = await axios.get("list-tutorials");
            return result.data;
        } catch(error) {
            throw error;
        }
    }

    async getTutorialByID(id) {
        try{
            const result = await axios.get("get-tutorial-byid/" + id);
            return result.data;
        } catch(error) {
            throw error;
        }
    }

}

export default new APIService();