import React from 'react';

const useUtilityHelpers = () => {

    const convertInputTime = (dateTimeInput) => {
        const dateObject = new Date(dateTimeInput);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");

        const hours = String(dateObject.getUTCHours()).padStart(2, "0");
        const minutes = String(dateObject.getUTCMinutes()).padStart(2, "0");

        const outputDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        return outputDateTime;
    }

    const randomMix = Math.floor(Math.random() * (100 - 2 + 1)) + 2;

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return { convertInputTime, randomMix,  timeout}
}

export default useUtilityHelpers;