import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from '../../services/api/api.service';
import { show, hide } from '../slices/loader.slice';


const fetchTutorialsByDepartmentAPI = async (departmentID) => {
    const response = await ApiService.getTutorialsByDepartments(departmentID);
    return response;
}

export const fetchTutorialsByDepartment = createAsyncThunk('tutorials/fetchTutorialsByDepartment', async (departmentID, {dispatch}) => {
    dispatch(show()); 
    const data = await fetchTutorialsByDepartmentAPI(departmentID);
    dispatch(hide()); 
    return data;
});

const TutorialsDepartmentSlice = createSlice({
    name: 'tutorialDepartment',
    initialState:{
        tutorials: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTutorialsByDepartment.fulfilled, (state, action) => {
                state.tutorials = action.payload
            });
    }
})

export default TutorialsDepartmentSlice.reducer;