import React, {useState} from 'react';
import classNames from "classnames";
import SideBar from './Sidebar';
import { Outlet } from "react-router-dom";
import LoadingComponent from './Loading';
import NavbarMain from './NavbarMain';

const Layout = () => {
    const [collapsed, setSidebarCollapsed] = useState(false);

    return (
        <>
            <LoadingComponent />
            <div
                className={classNames({
                    // 👇 use grid layout
                    "grid min-h-screen": true,
                    // 👇 toggle the width of the sidebar depending on the state
                    "grid-cols-sidebar": !collapsed,
                    "grid-cols-sidebar-collapsed": collapsed,
                    // 👇 transition animation classes
                    "transition-[grid-template-columns] duration-300 ease-in-out": true,
                })}
                >
                    <SideBar
                        collapsed={collapsed}
                        setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
                    />
                    {/* content */}
                    <div className="bg-white">
                        <NavbarMain onMenuButtonClick={() => setSidebarCollapsed((prev) => !prev)}/>
                        <Outlet />
                    </div>                
            </div>
        </>
    )
}

export default Layout;