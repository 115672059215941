import React, {useState, useEffect, useRef }  from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { UsersIcon, CalendarDaysIcon, PencilIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Typography,
    Avatar, 
    Button,
    Alert,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Textarea  
} from "@material-tailwind/react";

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

import SelectComponent from '../components/SelectComponent';

import { useDispatch, useSelector } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";

import ApiService from '../services/api/api.service';

const Icon = ({id, open})  => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}


const EditLinkPage = () => {

    const pageTitle = "Edit Link";
    const pageDescription = "Update selected link.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Links", url: "/manage-links" },
        { label: "Edit Link", url: "/" },                
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedDepartment, setSelectedDepartment] = useState({id: '2', name: 'School of Chemical Sciences'});

    const [openSaveAlert, setOpenSaveAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [name, setName] = useState("");
    const [link, setLink] = useState("");

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleUpdateLink = async () => {

        dispatch(show());
        try {
            const addLinkResult = await ApiService.protectedUpdate('link', {
                id: searchParams.get("id"),
                nameLink: name,
                linkLink: link,
                dpartment_id:  selectedDepartment.id
            });
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide()); 
        setAlertMessage("The link " + name + " has been updated!");
        setOpenSaveAlert(true);
        const Tout = await timeout(2000);
        navigate('/manage-links');
        return null;
    }

    const handleDeleteLink = async () => {

        dispatch(show());
        try {
            const deleteLinkResult = await ApiService.protectedDelete('link?id=' + searchParams.get("id"));
            console.log(deleteLinkResult);
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide()); 
        setAlertMessage("The link " + name + " has been deleted!");
        setOpenSaveAlert(true);
        const Tout = await timeout(2000);
        navigate('/manage-links');
        return null;

    }

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);

    useEffect(() => {

        dispatch(show());

        ( async () => {                
            try {
                const getLinkByIDResponse = await ApiService.getLinkByID(searchParams.get("id"));
                setSelectedDepartment({id: getLinkByIDResponse.department_id,  name: getLinkByIDResponse.department_name})
                setLink(getLinkByIDResponse.link);
                setName(getLinkByIDResponse.name);
            } catch(error) {
                navigate('/login');
            }
        })();

        dispatch(hide());

    }, []);
    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                        open={openSaveAlert}
                        onClose={() => setOpenSaveAlert(false)}
                        animate={{
                        mount: { y: 0 },
                        unmount: { y: 100 },
                        }}
                        color="green"
                    >
                        {alertMessage}
                </Alert>

                <Card className="h-full w-full">

                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-4 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>                                
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="px-5">

                        <div className="mb-5">
                            <SelectComponent selected={selectedDepartment} setSelected={setSelectedDepartment} />
                        </div>

                        <div className="mb-5">
                            <Input
                                type="text"
                                size="md" 
                                label="Name"
                                id="name"
                                autoComplete="off"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="mb-5">
                            <Input
                                type="text"
                                size="md" 
                                label="Link"
                                id="link"
                                autoComplete="off"
                                required
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </div>

                        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            <Button variant="gradient" className="flex items-center gap-3"  onClick={handleUpdateLink}>
                                <svg
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-5 w-5"
                                    >
                                    <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                                </svg>                                                    
                                Update Link
                            </Button>

                            <Button variant="gradient" className="flex items-center gap-3"  color="red" onClick={handleDeleteLink}>
                                <svg
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        className="h-5 w-5"
                                    >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M4 8h16v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8zm3-3V3a1 1 0 011-1h8a1 1 0 011 1v2h5v2H2V5h5zm2-1v1h6V4H9zm0 8v6h2v-6H9zm4 0v6h2v-6h-2z" />
                                </svg>                                                    
                                Delete Department
                            </Button>
                        </div>

                    </CardBody>



                </Card>

            </div>
        </div>
    );
}

export default EditLinkPage;