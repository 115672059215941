import React from 'react'

import LinkLIstContainer from '../components/LinkLIstContainer';

const LinkListPage = () => {

    const TABLE_HEAD = ["ID", "Name", "Department", ""];

    return (
    <>
      <LinkLIstContainer filtersArray={["name", "link"]} TABLE_HEAD={TABLE_HEAD} resourceName="linkList" resourcePath="link" editURL="/edit-link" addURL="/add-link" />
    </>
  )
}

export default LinkListPage;