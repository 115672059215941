import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice({
    name: 'loader',
    initialState: {
        show: false
    },
    reducers: {
        show: (state) => {
            state.show = true;
        },
        hide: (state) =>  {
            state.show = false;
        }
    }
})

export const {show, hide} = LoaderSlice.actions;

export default LoaderSlice.reducer;