import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useRefreshToken from '../store/hooks/useRefreshToken';
import useAuth from '../store/hooks/useAuth';
import loadingWheel from "../assets/loading.svg";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <div className="flex fixed z-[1000] backdrop-blur-md h-full w-full items-center justify-center">
                        <img src={loadingWheel} alt="" className="w-20 h-20" />
                      </div>
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin