import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PencilIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Typography,
    Avatar, 
    Button,
    Alert
 } from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import { show, hide } from '../store/slices/loader.slice';
import { showMini, hideMini } from '../store/slices/miniLoader.slice';
import { fetchProfile, fetchProfileSuccess } from '../store/slices/profile.slice';
import { fetchDepartment, fetchDepartmentSuccess } from '../store/slices/department.slice';
import { fetchImageProfileSuccess } from '../store/slices/profileImage.slice';

import LoadingMini from '../components/LoadingMini';

import PositionComponent from '../components/PositionComponent';

import useAuth from '../store/hooks/useAuth';
import SelectComponent from '../components/SelectComponent';

import ApiService from '../services/api/api.service';

const NAME_REGEX = /^[A-zA-Z][a-zA-Z0-9-_ ]{2,23}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PHONE_REGEX = /^02[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$/;

const MyProfilePage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { auth } = useAuth();

    const isFetchingProfile = useSelector((state) => state.profileReducer.isFetchingProfile);
    const currentProfile = useSelector((state) => state.profileReducer.profile);
    const currentDepartments = useSelector((state) => state.departmentReducer.departments);

    const pageTitle = "My Profile Page";
    const pageDescription = "Update profile information from this page.";

    const [positionsArray, setPositionsArray] = useState([{value: "mentor",isSelected: false},{value: "staff",isSelected: false},{value: "coordinator",isSelected: false}]);

    const firstNameRef = useRef();
    const errRef = useRef();
    const imageProfileRef = useRef();

    const [firstName, setFirstName] = useState('');
    const [validFirstName, setValidFirstName] = useState(false);
    const [firstNameFocus, setFirstNameFocus] = useState(false);

    const [lastName, setLastName] = useState('');
    const [validLastName, setValidLastName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [avatarImage, setAvatarImage] = useState("/images/members/no-image.jpg");

    const [selectedDepartment, setSelectedDepartment] = useState({id: '2', name: 'School of Chemical Sciences'});
    const [selectedCMSRole, setSelectedCMSRole] = useState("user");

    const [openSaveAlert, setOpenSaveAlert] = useState(false);

    const [isVerified, setIsVerified] = useState(false);

    const handleAvatarChange = () => {        
        if(imageProfileRef.current) {
            imageProfileRef.current.click();
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("userID", auth.userid);

        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("department_id", selectedDepartment.id);

        if (selectedCMSRole.name === 'admin') {
            formData.append("role", "300,500");
        } else {
            formData.append("role", "300");
        }

        formData.append("is_verified", isVerified);

        let currentPositionArray = [];
        positionsArray.forEach((item) => {
            if(item.isSelected) {
                currentPositionArray.push(item.value);
            }
        });

        const positionString = currentPositionArray.join();
        formData.append("position", positionString);

        dispatch(show());
        try {
            const updateMemberProfileResult = await ApiService.updateMemberProfile(formData);
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide());
        setOpenSaveAlert(true);
    }

    const handleInputFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);
        formData.append("userID", auth.userid);

        dispatch(showMini());
        try {
            const updateMemberImageProfileResult = await ApiService.updateMemberImageProfile(formData);
        } catch(error) {
            navigate('/login');
        }        
        setAvatarImage(URL.createObjectURL(e.target.files[0]));
        dispatch(fetchImageProfileSuccess(URL.createObjectURL(e.target.files[0])));

        dispatch(hideMini());
    }
    
    const getUserProfile = async () => {
        const formData = new FormData(); 

        formData.append("userid", auth.userid);

        dispatch(show());
        try {
            const getUserProfileResponse = await ApiService.getUserProfile(formData);
            dispatch(fetchProfileSuccess(getUserProfileResponse));
        } catch(error) {
            navigate('/login');
        }
        try {
            const getDepartmentsResponse = await ApiService.getDepartmentsIDNameTuple();
            dispatch(fetchDepartmentSuccess(getDepartmentsResponse));        
        } catch(error) {
            navigate('/login');
        }        

        dispatch(hide());
    }

    useEffect(() => {
        if(!isFetchingProfile) {
            dispatch(fetchProfile());
        }
    }, []);

    useEffect(() => {
        if(isFetchingProfile) {
            getUserProfile();
        }
    }, [isFetchingProfile]);

    useEffect(() => {

        setIsVerified(prev => currentProfile.is_verified);
        setFirstName(prev => currentProfile.first_name);
        setLastName(prev => currentProfile.last_name);
        setEmail(currentProfile.email);
        setPhone(currentProfile.phone);
        (currentProfile.role == "300,500") ? setSelectedCMSRole("admin") : setSelectedCMSRole("user");

        if (currentProfile.image_file !== "") {
            setAvatarImage("/images/members/" + currentProfile.image_file);
            dispatch(fetchImageProfileSuccess("/images/members/" + currentProfile.image_file));
        }

        if(currentProfile.position !== "") {
            const positionArray = currentProfile.position.split(",");
            setPositionsArray([{value: "mentor",isSelected: positionArray.includes("mentor")},{value: "staff",isSelected: positionArray.includes("staff")},{value: "coordinator",isSelected: positionArray.includes("coordinator")}]);
        }        
        
        if(currentDepartments) {
            const currDepartment = currentDepartments.filter((department => department.id == currentProfile.department_id));
            setSelectedDepartment({id: currentProfile.department_id.toString(), name: currDepartment[0]?.name});
        }
        
    }, [currentProfile, currentDepartments]);

    useEffect(() => {
        firstNameRef.current.focus();
    }, []);

    useEffect(() => {
        setValidFirstName(NAME_REGEX.test(firstName));
    }, [firstName]);

    useEffect(() => {
        setValidLastName(NAME_REGEX.test(lastName));
    }, [lastName]);

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone]);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email]);

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                    open={openSaveAlert}
                    onClose={() => setOpenSaveAlert(false)}
                    animate={{
                    mount: { y: 0 },
                    unmount: { y: 100 },
                    }}
                >
                    Your profile has been updated.
                </Alert>

                <Card className="h-full w-full">
                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-4 flex items-center justify-between gap-8">
                            <div>
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="px-5">
                        
                         <div className='flex justify-between'>

                            <div className='shrink-0 w-56 border-r-2 border-solids border-gray-300 flex items-center justify-center flex-col px-2'>
                                <div className="relative">
                                    <LoadingMini classProp={"h-[110px] w-[110px]"} />
                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-full z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleAvatarChange}>
                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                    </div>
                                    <Avatar src={avatarImage} alt="avatar" size="xxl"/>
                                    <input type="file" className="hidden" onChange={handleInputFileChange} ref={imageProfileRef} />
                                </div>                                
                                <div className="pt-4 text-center">
                                    Upload a square photo at least 200px by 200px. Click the image above to change.
                                </div>
                            </div>

                            <div className='flex-1 px-4'>
                                    <form onSubmit={(e) => handleOnSubmit(e)}>
                                            <div className='flex w-96 flex-col gap-4 p-5 pt-0 mt-0'>
                                                <Typography className="" variant="small">
                                                    CMS Role :  <span className="font-bold">{selectedCMSRole}</span>
                                                </Typography>                                                
                                                <Input 
                                                    type="text" 
                                                    size="md" 
                                                    label="First Name"
                                                    id="firstName"
                                                    autoComplete="off"
                                                    required
                                                    inputRef={firstNameRef}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    value={firstName}
                                                    aria-invalid={validFirstName ? "false" : "true"}
                                                    aria-describedby="fndnote"
                                                    onFocus={() => setFirstNameFocus(true)}
                                                    onBlur={() => setFirstNameFocus(false)}
                                                    error={validFirstName || !firstName ? false : true}
                                                    success={validFirstName ? true : false}
                                                />
                                                <p id="fndnote" className={firstNameFocus && firstName && !validFirstName ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                    2 to 24 characters.                                
                                                </p>
                                                <Input
                                                    type="text"
                                                    size="md" 
                                                    label="Last Name"
                                                    id="lastName"
                                                    autoComplete="off"
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    value={lastName}
                                                    required
                                                    aria-invalid={validLastName ? "false" : "true"}
                                                    aria-describedby="lnnote"
                                                    onFocus={() => setLastNameFocus(true)}
                                                    onBlur={() => setLastNameFocus(false)}                                
                                                    error={validLastName || !lastName ? false : true}
                                                    success={validLastName ? true : false}
                                                />
                                                <p id="lnnote" className={lastNameFocus && lastName && !validLastName ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                    2 to 24 characters.                                
                                                </p>
                                                <Input 
                                                    type="email"
                                                    size="md" 
                                                    label="Email"
                                                    id="email"
                                                    autoComplete="off"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    required
                                                    aria-invalid={validEmail ? "false" : "true"}
                                                    aria-describedby="emailnote"
                                                    onFocus={() => setEmailFocus(true)}
                                                    onBlur={() => setEmailFocus(false)}                                
                                                    error={validEmail || !email ? false : true}
                                                    success={validEmail ? true : false}
                                                />
                                                <p id="emailnote" className={emailFocus && email && !validEmail ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                    Must be a valid email address
                                                </p>
                                                <Input 
                                                    type="phone"
                                                    size="md" 
                                                    label="Phone"
                                                    id="phone"
                                                    autoComplete="off"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                    required
                                                    aria-invalid={validPhone ? "false" : "true"}
                                                    aria-describedby="phonenote"
                                                    onFocus={() => setPhoneFocus(true)}
                                                    onBlur={() => setPhoneFocus(false)}                                
                                                    error={validPhone || !phone ? false : true}
                                                    success={validPhone ? true : false}
                                                />
                                                <p id="phonenote" className={phoneFocus && phone && !validPhone ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                    Must be a valid nz phone number
                                                </p>

                                                <SelectComponent selected={selectedDepartment} setSelected={setSelectedDepartment} />

                                            </div>

                                            <div>
                                                <Typography className="ml-5" variant="small">
                                                    Position :
                                                </Typography>
                                                <PositionComponent optionsArray={positionsArray} setOptionsArray={setPositionsArray} />
                                            </div>
                                        
                                            <div className="mt-5 ml-5">
                                                <Button variant="gradient" className="flex items-center gap-3" type="submit">
                                                    <svg 
                                                            fill="none" 
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="h-5 w-5"
                                                        >
                                                        <path
                                                            fill="currentColor"
                                                            d="M9 14a1 1 0 100-2 1 1 0 000 2zM16 13a1 1 0 11-2 0 1 1 0 012 0z"
                                                        />
                                                        <path
                                                            fill="currentColor"
                                                            fillRule="evenodd"
                                                            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 007.634-10.4c-.835.226-1.713.346-2.619.346a9.996 9.996 0 01-8.692-5.053A8 8 0 0012 20z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>                                                    
                                                    Update Profile
                                                </Button>
                                            </div>
                                    </form>
                            </div>
                         </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default MyProfilePage;
