import React, {useState, useEffect, useRef }  from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Typography,
    Avatar, 
    Button,
    Alert,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Textarea  ,    
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import { fetchTutorialsByID } from '../store/slices/tutorial.slice';

import {show, hide} from "../store/slices/loader.slice";

import ApiService from '../services/api/api.service';

import LoadingMiniCustom from '../components/LoadingMiniCustom';

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import SelectComponent from '../components/SelectComponent';
import { fetchDepartment, fetchDepartmentSuccess } from '../store/slices/department.slice';

import useMembersByTutorialID from '../components/hooks/useMembersByTutorialID';
import useUtilityHelpers from '../components/hooks/useUtilityHelpers';

const Icon = ({id, open})  => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const EditTutorialPage = () => {

    const pageTitle = "Edit Tutorial";
    const pageDescription = "Update selected tutorial.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Tutorials", url: "/manage-tutorials" },
        { label: "Edit Tutorial", url: "/" },        
    ]

    const mapFileRef = useRef();
    const campusMapFileRef = useRef();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const tutorialDetails = useSelector((state) => state.tutorialsReducer.tutorialDetails);

    const [openAccordion, setOpenAccordion] = React.useState(4);
    const handleOpenAccordion = (value) => setOpenAccordion(openAccordion === value ? 0 : value);

    const [openSaveAlert, setOpenSaveAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [startDateValue, setStartDateValue] = useState(dayjs('2022-04-17T15:30'));
    const [endDateValue, setEndDateValue] = useState(dayjs('2022-04-17T15:30'));

    const [details, setDetails] = useState("");
    const [location, setLocation] = useState("");
    const [roomMapVideo, setRoomMapVideo] = useState("");

    const [roomMapImage, setRoomMapImage] = useState("");
    const [roomMapImageFileName, setRoomMapImageFileName] = useState("default_mapimage.png");    

    const [campusMap, setCampusMap] = useState("");
    const [campusMapFileName, setCampusMapFileName] = useState("pdf_default.png");
    
    const [showLoadingImageFile, setShowLoadingImageFile] = useState(false);
    const [showLoadingMapFile, setShowLoadingMapFile] = useState(false);
    const [showLoadingCampusMap, setShowLoadingCampusMap] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState({id: '2', name: 'School of Chemical Sciences'});

    const { convertInputTime, randomMix, timeout } = useUtilityHelpers();

    const handleMapFileChangeTrigger = () => {        
        if(mapFileRef.current) {
            mapFileRef.current.click();
        }
    }

    const handleCampusMapFileChangeTrigger = () => {        
        if(campusMapFileRef.current) {
            campusMapFileRef.current.click();
        }
    }

    const handleMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("imageFile", e.target.files[0]);

        setShowLoadingMapFile(true);

        try {
            const uploadTutorialLocationImageFileResult = await ApiService.uploadTutorialLocationImageFile(formData);
            setRoomMapImageFileName(uploadTutorialLocationImageFileResult);
            setRoomMapImage(URL.createObjectURL(e.target.files[0]));
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingMapFile(false);
    }    

    const handleCampusMapFileChange = async (e) => {
        const formData = new FormData();

        formData.append("campusMapFile", e.target.files[0]);

        setShowLoadingCampusMap(true);

        try {
            const uploadTutorialCampusMapFileResult = await ApiService.uploadTutorialCampusMapFile(formData);            
            setCampusMapFileName(uploadTutorialCampusMapFileResult);
        } catch(error) {
            navigate('/login');
        }

        setShowLoadingCampusMap(false);
    }


    const handleUpdateTutorial = async () => {

        dispatch(show());
        try {
            const updateTutorialResult = await ApiService.protectedUpdate('update-tutorial', {
                tutorial_id: searchParams.get("id"),
                start_date: dayjs(startDateValue).format('YYYY-MM-DD HH:mm'),
                end_date: dayjs(endDateValue).format('YYYY-MM-DD HH:mm'),
                details:  details,
                location: location,
                department_id: selectedDepartment.id,
                room_map_video: roomMapVideo,
                room_map_image_file: roomMapImageFileName,
                campus_map_file: campusMapFileName
            });
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide()); 
        setAlertMessage("The tutorial with id " + searchParams.get("id") + " has been updated!");
        setOpenSaveAlert(true);
        const Tout = await timeout(2000);
        navigate('/manage-tutorials');
        return null;
    }

    const handleDeleteTutorial = async () => {

        dispatch(show());
        try {
            const deleteResult = await ApiService.protectedDelete('delete-tutorial?id=' + searchParams.get("id"));
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide()); 
        setAlertMessage("The tutorial with id " +  searchParams.get("id") + " has been deleted!");
        setOpenSaveAlert(true);
        const Tout = await timeout(2000);
        navigate('/manage-tutorials');
        return null;

    }

    const handleDeleteMentor = async (id) => {
        dispatch(show());
        try {
            const deleteLinkResult = await ApiService.protectedDelete('delete-tutorial-tutor?tutorialID=' + searchParams.get("id") + '&memberID=' + id);
            const newMembers = members.filter((item) => item.id != id);
            setMembers(newMembers);            
        } catch(error) {
            navigate('/login');
        }
        dispatch(hide());
    }

    const handleAddMentor = () => {
        navigate('/pick-member?id=' + searchParams.get("id"));
    }

    const TABLE_HEAD = ["ID", "Name", "Department", ""];

    const { members, setMembers } = useMembersByTutorialID(searchParams.get("id"));

    useEffect(() => {
        if (tutorialDetails) {
            setStartDateValue(dayjs(convertInputTime(tutorialDetails.start_date)));
            setEndDateValue(dayjs(convertInputTime(tutorialDetails.end_date)));
            setDetails(tutorialDetails.details);
            setLocation(tutorialDetails.location);
            setRoomMapVideo(tutorialDetails.room_map_video);
            setCampusMap("/images/tutorials/pdf_default.png");
            if (tutorialDetails.campus_map) {
                setCampusMap("/images/tutorials/" + tutorialDetails.campus_map);
            }            
            setRoomMapImage("/images/tutorials/default_mapimage.png");
            if (tutorialDetails.room_map_image) {
                setRoomMapImage("/images/tutorials/" + tutorialDetails.room_map_image);
            }
            setSelectedDepartment({id: tutorialDetails.department_id, name: tutorialDetails.department_name});
        }
    }, [tutorialDetails]);

    useEffect(() => {
        dispatch(fetchTutorialsByID(searchParams.get("id")));        
    }, [dispatch])

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                        open={openSaveAlert}
                        onClose={() => setOpenSaveAlert(false)}
                        animate={{
                        mount: { y: 0 },
                        unmount: { y: 100 },
                        }}
                        color="green"
                    >
                        {alertMessage}
                </Alert>

            <Card className="h-full w-full">

                <CardHeader floated={false} shadow={false} className="rounded-none">
                    <div className="mb-4 flex items-center justify-between gap-8">
                        <div>
                            <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                            <Typography variant="h5" color="blue-gray">
                                {pageTitle}
                            </Typography>
                            <Typography color="gray" className="mt-1 font-normal">
                                {pageDescription}
                            </Typography>                                
                        </div>
                        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                        </div>
                    </div>
                </CardHeader>

                
                <CardBody className="px-5">

                    <div>

                        <div className="mb-5">
                            <SelectComponent selected={selectedDepartment} setSelected={setSelectedDepartment} />
                        </div>

                        <Accordion open={openAccordion === 1} icon={<Icon id={1} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 
                
                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(1)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Dates
                            </AccordionHeader>

                            <AccordionBody className="flex">
                                    
                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDateValue}
                                                onChange={(newValue) => setStartDateValue(newValue)}
                                            />
                                        </DemoContainer>

                                    </LocalizationProvider>
                                </div>

                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDateValue}
                                                onChange={(newValue) => setEndDateValue(newValue)}
                                            />
                                        </DemoContainer>

                                    </LocalizationProvider>
                                </div>

                            </AccordionBody>

                        </Accordion>

                        <Accordion open={openAccordion === 2} icon={<Icon id={2} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 

                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(2)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 2 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Tutorial Details
                            </AccordionHeader>

                            <AccordionBody>
                                <div>
                                    <Textarea 
                                        color="blue" 
                                        label="Content"
                                        id="details"
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                    />
                                </div>                                
                            </AccordionBody>

                        </Accordion>

                        <Accordion open={openAccordion === 3} icon={<Icon id={3} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 
                                
                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(3)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 3 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Location & Maps
                            </AccordionHeader>

                            <AccordionBody>
                                <div className="mb-8">
                                    <Input
                                        type="text"
                                        size="md" 
                                        label="Location"
                                        id="location"
                                        autoComplete="off"
                                        required
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <Input 
                                        type="text" 
                                        size="md" 
                                        label="Room Location Video"
                                        id="roomMapVideo"
                                        autoComplete="off"                                                    
                                        value={roomMapVideo}
                                        onChange={(e) => setRoomMapVideo(e.target.value)}
                                    />
                                </div>
                                <div className="mt-3 mb-8">
                                    Copy and paste a youtube video embeded content markup. See <a href="https://support.google.com/youtube/answer/171780?hl=en" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank">youtube video embed share</a>.
                                </div>
                                <div className="flex">
                                    <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                            <div className="relative h-[110px] w-[110px]">
                                                <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingMapFile} />
                                                <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleMapFileChangeTrigger}>
                                                    <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                </div>
                                                <Avatar src={roomMapImage} alt="avatar" size="xxl" variant="rounded" />
                                                <input type="file" className="hidden" onChange={handleMapFileChange} ref={mapFileRef} />
                                            </div>
                                            <div className="font-bold">
                                                Room Location Image
                                            </div>
                                            <div className="mx-4 text-center">
                                                Upload a photo with a square dimension not less than 200px width by 200px height. Click the image above to change.
                                            </div>
                                    </div>
                                    <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                            <div className="relative h-[110px] w-[110px]">
                                                <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingCampusMap} />
                                                <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleCampusMapFileChangeTrigger}>
                                                    <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                </div>
                                                <Avatar src={"/images/tutorials/" + campusMapFileName} alt="avatar" size="xxl" variant="rounded" />
                                                <input type="file" className="hidden" onChange={handleCampusMapFileChange} ref={campusMapFileRef} />
                                            </div> 
                                            <div className="font-bold">
                                                Campus Map (pdf)
                                            </div>
                                            <div className="mx-4 text-center">
                                                Upload a pdf file otherwise this will default to the official campus map link.
                                            </div>
                                    </div>
                                </div>                            
                            </AccordionBody>

                        </Accordion>

                        <Accordion open={openAccordion === 4} icon={<Icon id={4} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4"> 

                            <AccordionHeader 
                                    onClick={() => handleOpenAccordion(4)}
                                    className={`border-b-0 transition-colors ${
                                        openAccordion === 4 ? "text-blue-500 hover:!text-blue-700" : ""
                                    }`}
                            >
                                Mentors / Tutors
                            </AccordionHeader>

                            <AccordionBody>

                                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                                    <Button className="flex items-center gap-3" color="blue" size="sm" onClick={handleAddMentor}>
                                        Add Mentor
                                    </Button>
                                </div>

                                <table className="mt-4 w-full min-w-max table-auto text-left">

                                    <thead>
                                        <tr>
                                        {TABLE_HEAD.map((head, index) => (
                                            <th
                                            key={head}
                                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                            >
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                            >
                                                {head}{" "}
                                                {index !== TABLE_HEAD.length - 1 && (
                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                                )}
                                            </Typography>
                                            </th>
                                        ))}
                                        </tr>
                                    </thead>    

                                    <tbody>

                                        { Array.isArray(members) ? members.map(({id, name, email, image_file, department}, index) => {

                                            return (
                                                <tr>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {id}
                                                        </Typography>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <div className="flex items-center gap-3">
                                                            <Avatar src={"/images/members/" + image_file + "?vr=89?vr=" + randomMix} alt={name} size="sm" />
                                                            <div className="flex flex-col">
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {name}
                                                                </Typography>
                                                                <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal opacity-70"
                                                                >
                                                                    {email}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                        <div className="flex flex-col">
                                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                                            {department}
                                                        </Typography>
                                                        </div>
                                                    </td>
                                                    <td className="p-4 border-b border-blue-gray-50">
                                                            <Tooltip content="Delete Member">
                                                                <IconButton variant="text" color="red" onClick={() => handleDeleteMentor(id) }>
                                                                    <XCircleIcon className="h-4 w-4" />
                                                                </IconButton>
                                                            </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        }) :  null }
                                        
                                        
                                        
                                    </tbody>

                                </table>

                            </AccordionBody>

                        </Accordion>

                        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                                    <Button variant="gradient" className="flex items-center gap-3" onClick={handleUpdateTutorial}>
                                                    <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="h-5 w-5"
                                                        >
                                                        <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                                                    </svg>                                                    
                                                    Update Tutorial
                                    </Button>

                                    <Button variant="gradient" className="flex items-center gap-3"  color="red" onClick={handleDeleteTutorial}>
                                        <svg
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                className="h-5 w-5"
                                            >
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path d="M4 8h16v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8zm3-3V3a1 1 0 011-1h8a1 1 0 011 1v2h5v2H2V5h5zm2-1v1h6V4H9zm0 8v6h2v-6H9zm4 0v6h2v-6h-2z" />
                                        </svg>                                                    
                                        Delete Tutorial
                                    </Button>
                        </div>

                    </div>

                </CardBody>


                

            </Card>

            </div>
        </div>
)}

export default EditTutorialPage;