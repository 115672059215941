import { createSlice } from "@reduxjs/toolkit";

const ProfileImageSlice = createSlice({
    name: 'profileImage',
    initialState: {
        error: null,
        isFetchingImageProfile: false,
        imageProfile: ""
    },
    reducers: {
        fetchImageProfile: (state) => {
            state.isFetchingImageProfile = true;
        },
        fetchImageProfileSuccess: (state, action) => {
            state.error = null;
            state.isFetchingImageProfile = false;
            state.imageProfile = action.payload;
        },
        fetchImageProfileFail: (state, action) => {
            state.error = action.payload;
            state.isFetchingImageProfile = false;
        }
    }
})

export const {fetchImageProfile, fetchImageProfileSuccess, fetchImageProfileFail} = ProfileImageSlice.actions;

export default ProfileImageSlice.reducer;