import { createSlice } from "@reduxjs/toolkit";

const MiniLoaderSlice = createSlice({
    name: 'miniloader',
    initialState: {
        showMiniFlag: false
    },
    reducers: {
        showMini: (state) => {
            state.showMiniFlag = true;
        },
        hideMini: (state) =>  {
            state.showMiniFlag = false;
        }
    }
})

export const {showMini, hideMini} = MiniLoaderSlice.actions;

export default MiniLoaderSlice.reducer;