import React from 'react';
import { Outlet } from "react-router-dom";
import LoadingComponent from './Loading';


const LayoutNoHeader = () => {
    return (
        <>
            <LoadingComponent />
            <Outlet />
        </>
    )
}

export default LayoutNoHeader;