import { createSlice } from "@reduxjs/toolkit";

const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
        error: null,
        isFetchingProfile: false,
        profile: {
            id:'',
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            image_file: '',
            role: '',
            position: '',
            department_id: '',
            is_verified: false           
        },
    },
    reducers: {
        fetchProfile: (state) => {
            state.isFetchingProfile = true;
        },
        fetchProfileSuccess: (state, action) => {
            state.error = null;
            state.isFetchingProfile = false;
            state.profile = action.payload;
        },
        fetchProfileFail: (state, action) => {
            state.error = action.payload;
            state.isFetchingProfile = false;
        }
    }
})

export const {fetchProfile, fetchProfileSuccess, fetchProfileFail} = ProfileSlice.actions;

export default ProfileSlice.reducer;