import React, {useRef, useState, useEffect } from 'react';
import { Input, Button } from "@material-tailwind/react";
import { BsGoogle, BsFacebook } from 'react-icons/bs';
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import background from "../assets/login-background.jpg";
import { baseURL } from "../services/helper/axios.helper";
import useAuth from '../store/hooks/useAuth';

const LoginPage = () => {


    const { setAuth, persist, setPersist } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    const loginRedirect = () => {
        window.location.href = baseURL + "/login";
    }
    

    useEffect(() => {
        setPersist(true);
        if(searchParams.get("verified") && searchParams.get("verified") == 0) {
            setShowMessage(true);
            setMessage("The admin will verify and activate your account.  Try logging in next time otherwise you can also email d.dimalen@auckland.ac.nz for assistance.");
        }
    },[])
    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])
    return (
        <div style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className="backdrop-blur-sm w-full h-full">
                <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
                    <div className="text-center mb-5 text-gray-600 text-2xl font-semibold drop-shadow-lg">Tuakana CMS</div>
                    <div className="bg-white sm:mx-auto sm:max-w-lg sm:rounded-lg border border-gray-400">
                        <div className="leading-3 w-full h-9 p-4 pb-7 text-center">
                            <p className={showMessage ? "text-xs text-red-800" : "hidden"} aria-live="assertive">{message}</p>                            
                        </div>  
                        <div className="w-full relative p-4">
                            <div className="text-gray-900 font-light text-center">Click the login button. When asked for an email, please use your "(upi)@aucklanduni.ac.nz" email account to login.</div>
                        </div>
                        <div className='flex w-72 flex-col gap-4 p-5 pb-8 mx-auto'>
                            <Button color="blue" className="flex flex-row items-center justify-center" onClick={() => loginRedirect()}>Login</Button>
                            {/* <Button color="blue" className="flex flex-row items-center justify-center"><BsFacebook className="text-white h-3 w-3 mr-3" /> Sign in with Facebook</Button> */}
                        </div>                        
                        <div className="w-full relative pb-5 text-center text-sm">
                            
                        </div>
                    </div>                
                </div>
            </div>
        </div>
    )
}

export default LoginPage;