import React, {useState, useEffect, useRef }  from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PencilIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Typography,
    Avatar, 
    Button,
    Alert,
    Accordion,
    AccordionHeader,
    AccordionBody,
 } from "@material-tailwind/react";

import { useDispatch } from 'react-redux';
import {show, hide} from "../store/slices/loader.slice";

import LoadingMiniCustom from '../components/LoadingMiniCustom';

import ApiService from '../services/api/api.service';

import BreadcrumbsComponent from '../components/BreadCrumbsComponent';

const Icon = ({id, open})  => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const NAME_REGEX = /^[A-zA-Z][a-zA-Z0-9-_ ]{2,100}$/;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const AddDepartmentPage = () => {

    const pageTitle = "Add Department";
    const pageDescription = "Add a new department.";

    const BreadcrumbItems = [
        { label: "Home", url: "/" },
        { label: "Departments", url: "/manage-departments" },
        { label: "Add Department", url: "/" },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const imageFileRef = useRef();
    const mapFileRef = useRef();
    const campusMapFileRef = useRef();
    
    const nameRef = useRef();

    const [name, setName] = useState("");
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);


    const [tereo, setTereo] = useState("");
    const [validTereo, setValidTereo] = useState(false);
    const [tereoFocus, setTereoFocus] = useState(false);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [location, setLocation] = useState("");
    const [validLocation, setValidLocation] = useState(false);
    const [locationFocus, setLocationFocus] = useState(false);

    const [topic, setTopic] = useState("");
    const [validTopic, setValidTopic] = useState(false);
    const [topicFocus, setTopicFocus] = useState(false);

    const [roomMapVideo, setRoomMapVideo] = useState("");

    const [campusMap, setCampusMap] = useState("");
    const [campusMapFile, setCampusMapFile] = useState("");

    const [roomMapImage, setRoomMapImage] = useState("");
    const [roomMapImageFile, setRoomMapImageFile] = useState("");

    const [imageFile, setImageFile] = useState("");    
    const [imageFileFile, setImageFileFile] = useState("");    

    const [showLoadingImageFile, setShowLoadingImageFile] = useState(false);
    const [showLoadingMapFile, setShowLoadingMapFile] = useState(false);
    const [showLoadingCampusMap, setShowLoadingCampusMap] = useState(false);

    const [openSaveAlert, setOpenSaveAlert] = useState(false);

    const [openAccordion, setOpenAccordion] = React.useState(1);

    const handleOpenAccordion = (value) => setOpenAccordion(openAccordion === value ? 0 : value);
    
    const getDepartments = async () => {
        
        dispatch(show());

        setCampusMap("/images/departments/pdf_default.png");
        setRoomMapImage("/images/departments/default_mapimage.png");
        setImageFile("/images/departments/default_imagefile.png");

        dispatch(hide());
    }

    const handleImageFileChangeTrigger = () => {        
        if(imageFileRef.current) {
            imageFileRef.current.click();
        }
    }

    const handleMapFileChangeTrigger = () => {        
        if(mapFileRef.current) {
            mapFileRef.current.click();
        }
    }

    const handleCampusMapFileChangeTrigger = () => {        
        if(campusMapFileRef.current) {
            campusMapFileRef.current.click();
        }
    }

    const handleImageFileChange = async (e) => {
        setImageFile(URL.createObjectURL(e.target.files[0]));
        setImageFileFile(e.target.files[0]);
    }

    const handleMapFileChange = async (e) => {
        setRoomMapImage(URL.createObjectURL(e.target.files[0]));
        setRoomMapImageFile(e.target.files[0]);
    }

    const handleCampusMapFileChange = async (e) => {
        setCampusMap("/images/departments/pdf_default_selected.png");
        setCampusMapFile(e.target.files[0]);
    }


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("name", name);
        formData.append("tereo", tereo);
        formData.append("email", email);
        formData.append("location", location);
        formData.append("topic", topic);
        formData.append("room_map_video", roomMapVideo);

        if (imageFileFile !== "") {
            formData.append("imageFileFile", imageFileFile);
        }
        
        if (roomMapImageFile !== "") {
            formData.append("roomMapImageFile", roomMapImageFile);
        }

        if (campusMapFile !== "") {
            formData.append("campusMapFile", campusMapFile);
        }

        dispatch(show());
        try {
            const updateDepartmentResult = await ApiService.addDepartment(formData);
        } catch(error) {
            navigate('/login');
        }        
        dispatch(hide());        
        setOpenSaveAlert(true);
        setTimeout(() => {
            navigate('/manage-departments');               
        }, 2000);
    }


    useEffect(() => {
        getDepartments();            
    }, []);

    useEffect(() => {
        if (openSaveAlert) {
            const timer = setTimeout(() => {
                setOpenSaveAlert(false);                
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openSaveAlert]);

    useEffect(() => {
        nameRef.current.focus();
    }, []);

    useEffect(() => {
        setValidName(NAME_REGEX.test(name));
    }, [name]);

    useEffect(() => {
        setValidTereo(NAME_REGEX.test(tereo));
    }, [tereo]);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email]);

    useEffect(() => {
        setValidLocation(NAME_REGEX.test(location));
    }, [location]);

    useEffect(() => {
        setValidTopic(NAME_REGEX.test(topic));
    }, [topic]);

    return (
        <div className='flex min-h-screen flex-col'>
            <div className="p-4">

                <Alert
                    open={openSaveAlert}
                    onClose={() => setOpenSaveAlert(false)}
                    animate={{
                    mount: { y: 0 },
                    unmount: { y: 100 },
                    }}
                >
                    Your profile has been updated.
                </Alert>

                <Card className="h-full w-full">

                    <CardHeader floated={false} shadow={false} className="rounded-none">
                        <div className="mb-4 flex items-center justify-between gap-8">
                            <div>
                                <BreadcrumbsComponent breadcrumbItems={BreadcrumbItems} />
                                <Typography variant="h5" color="blue-gray">
                                    {pageTitle}
                                </Typography>
                                <Typography color="gray" className="mt-1 font-normal">
                                    {pageDescription}
                                </Typography>                                
                            </div>
                            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody className="px-5">

                        <div className='flex justify-between'>

                            <div className='shrink-0 w-56 border-r-2 border-solids border-gray-300 flex items-center flex-col px-2'>
                                <div className="relative">
                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingImageFile} />
                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleImageFileChangeTrigger}>
                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                    </div>
                                    <Avatar src={imageFile} alt="avatar" size="xxl" variant="rounded" />
                                    <input type="file" className="hidden" onChange={handleImageFileChange} ref={imageFileRef} />
                                </div>
                                <div className="pt-4 text-center">
                                    Select an image with dimensions 800px width by 450px height. Click the image above to choose a file from your computer
                                </div>
                            </div>

                            <div className='flex-1 pl-4'>
                                <form onSubmit={(e) => handleOnSubmit(e)}>


                                    <Accordion open={openAccordion === 1} icon={<Icon id={1} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                                        <AccordionHeader 
                                            onClick={() => handleOpenAccordion(1)}
                                            className={`border-b-0 transition-colors ${
                                                openAccordion === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                                            }`}
                                        >
                                            Main
                                        </AccordionHeader>
                                        <AccordionBody className="flex">

                                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">

                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Name"
                                                        id="name"
                                                        autoComplete="off"
                                                        required 
                                                        inputRef={nameRef}
                                                        onChange={(e) => setName(e.target.value)}
                                                        value={name}                                                        
                                                        aria-invalid={validName ? "false" : "true"}
                                                        aria-describedby="noteName"
                                                        onFocus={() => setNameFocus(true)}
                                                        onBlur={() => setNameFocus(false)}
                                                        error={validName || !name ? false : true}
                                                        success={validName ? true : false}                                                                                                                                                                       
                                                    />
                                                    <p id="noteName" className={nameFocus && name && !validName ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                        2 to 100 characters.                                
                                                    </p>

                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Tereo"
                                                        id="tereo"
                                                        autoComplete="off"
                                                        required
                                                        onChange={(e) => setTereo(e.target.value)}
                                                        value={tereo}
                                                        aria-invalid={validTereo ? "false" : "true"}
                                                        aria-describedby="noteTereo"
                                                        onFocus={() => setTereoFocus(true)}
                                                        onBlur={() => setTereoFocus(false)}
                                                        error={validTereo || !tereo ? false : true}
                                                        success={validTereo ? true : false}                                                           
                                                    />
                                                    <p id="noteTereo" className={tereoFocus && tereo && !validTereo ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                        2 to 100 characters.                                
                                                    </p>

                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Topic"
                                                        id="topic"
                                                        autoComplete="off"
                                                        required
                                                        onChange={(e) => setTopic(e.target.value)}
                                                        value={topic}
                                                        aria-invalid={validTopic ? "false" : "true"}
                                                        aria-describedby="noteTopic"
                                                        onFocus={() => setTopicFocus(true)}
                                                        onBlur={() => setTopicFocus(false)}
                                                        error={validTopic || !topic ? false : true}
                                                        success={validTopic ? true : false}                                                           
                                                    />
                                                    <p id="noteTopic" className={topicFocus && topic && !validTopic ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                        2 to 100 characters.                                
                                                    </p>

                                                </div>

                                                <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0">

                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Email"
                                                        id="email"
                                                        autoComplete="off"
                                                        required                                                        
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        value={email}
                                                        aria-invalid={validEmail ? "false" : "true"}
                                                        aria-describedby="noteEmail"
                                                        onFocus={() => setEmailFocus(true)}
                                                        onBlur={() => setEmailFocus(false)}
                                                        error={validEmail || !email ? false : true}
                                                        success={validEmail ? true : false} 
                                                    />
                                                    <p id="noteEmail" className={emailFocus && email && !validEmail ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                        Must be a valid email address                                
                                                    </p>


                                                    <Input
                                                        type="text"
                                                        size="md" 
                                                        label="Location"
                                                        id="location"
                                                        autoComplete="off"
                                                        required
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        value={location}
                                                        aria-invalid={validLocation ? "false" : "true"}
                                                        aria-describedby="noteLocation"
                                                        onFocus={() => setLocationFocus(true)}
                                                        onBlur={() => setLocationFocus(false)}
                                                        error={validLocation || !location ? false : true}
                                                        success={validLocation ? true : false}                                                   
                                                    />
                                                    <p id="noteLocation" className={locationFocus && location && !validLocation ? "bg-black rounded-md text-white text-sm p-2" : "hidden"}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />                                
                                                        Must be a valid email address                                
                                                    </p>
                                                </div>

                                        </AccordionBody>
                                    </Accordion>

                                    <Accordion open={openAccordion === 2} icon={<Icon id={2} open={openAccordion} />} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                                        <AccordionHeader 
                                            onClick={() => handleOpenAccordion(2)}
                                            className={`border-b-0 transition-colors ${
                                                openAccordion === 2 ? "text-blue-500 hover:!text-blue-700" : ""
                                            }`}
                                        >
                                            Location Maps
                                        </AccordionHeader>
                                        <AccordionBody>
                                                    <Input 
                                                        type="text" 
                                                        size="md" 
                                                        label="Room Location Video"
                                                        id="roomMapVideo"
                                                        autoComplete="off"                                                    
                                                        value={roomMapVideo}
                                                        onChange={(e) => setRoomMapVideo(e.target.value)}
                                                    />
                                                    <div className="mt-3 mb-8">
                                                        Copy and paste a youtube video embeded content markup. See <a href="https://support.google.com/youtube/answer/171780?hl=en" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank">youtube video embed share</a>.
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                                                <div className="relative h-[110px] w-[110px]">
                                                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingMapFile} />
                                                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleMapFileChangeTrigger}>
                                                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                                    </div>
                                                                    <Avatar src={roomMapImage} alt="avatar" size="xxl" variant="rounded" />
                                                                    <input type="file" className="hidden" onChange={handleMapFileChange} ref={mapFileRef} />
                                                                </div>
                                                                <div className="font-bold">
                                                                    Room Location Image
                                                                </div>
                                                                <div className="mx-4 text-center">
                                                                    Select an image with a square dimension not less than 200px width by 200px height. Click the image above to choose a file from your computer.
                                                                </div>
                                                        </div>
                                                        <div className="w-1/2 flex flex-col gap-4 p-5 pt-0 mt-0 items-center">
                                                                <div className="relative h-[110px] w-[110px]">
                                                                    <LoadingMiniCustom classProp={"h-[110px] w-[110px]"} showMiniLoading={showLoadingCampusMap} />
                                                                    <div className="absolute cursor-pointer top-0 w-full h-full rounded-md z-20 opacity-0 hover:backdrop-blur-md hover:opacity-100 flex items-center justify-center"  onClick={handleCampusMapFileChangeTrigger}>
                                                                        <PencilIcon className='h-7 w-7 text-white text-2xl'/>
                                                                    </div>
                                                                    <Avatar src={campusMap} alt="avatar" size="xxl" variant="rounded" />
                                                                    <input type="file" className="hidden" onChange={handleCampusMapFileChange} ref={campusMapFileRef} />
                                                                </div> 
                                                                <div className="font-bold">
                                                                    Campus Map (pdf)
                                                                </div>
                                                                <div className="mx-4 text-center">
                                                                    Select a pdf file from your computer by clicking the image above.
                                                                </div>
                                                        </div>
                                                    </div>
                                        </AccordionBody>
                                    </Accordion>

                                    <div>
                                                <Button variant="gradient" className="flex items-center gap-3" type="submit">
                                                    <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2}
                                                            stroke="currentColor"
                                                            className="h-5 w-5"
                                                        >
                                                        <path d="M5 21h14a2 2 0 002-2V8l-5-5H5a2 2 0 00-2 2v14a2 2 0 002 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z" />
                                                    </svg>                                                    
                                                    Add Department
                                                </Button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </CardBody>

                </Card>

            </div>
        </div>
    )
}

export default AddDepartmentPage;