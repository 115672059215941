import React from 'react';
import { useSelector } from 'react-redux';
import loadingWheel from "../assets/loading.svg";

const Loading = () => {
    const showLoading = useSelector((state) => state.loaderReducer.show);

    return showLoading ? (
        <div className="flex fixed z-[10000] backdrop-blur-md h-full w-full items-center justify-center">
          <img src={loadingWheel} alt="" className="w-20 h-20" />
        </div>
      ) : null;
}

export default Loading;