import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import store from './store/store';
import { Provider } from 'react-redux';
import { AuthProvider } from './store/context/AuthProvider';

import App from './App';

const domNode = document.getElementById('app');

const root = createRoot(domNode);
root.render( 
    <BrowserRouter>
        <Provider store={store}>
            <AuthProvider>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </AuthProvider>
        </Provider>
    </BrowserRouter>
);