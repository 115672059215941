import axios from '../../services/helper/axios.helper';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {

        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
    
        const response = await axios.get('refresh', {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            }
        });
        setAuth(prev => {
            return { ...prev, userid: response.data.user.userid, roles: response.data.user.roles, accessToken: response.data.accessToken }
        });

        return response.data.accessToken;
    }

    console.clear();

    return refresh;
};

export default useRefreshToken;