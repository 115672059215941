import React, {useState, useEffect} from 'react';
import classNames from "classnames";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import {
    Typography,
    Button,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Avatar
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    ChevronDownIcon,
    LifebuoyIcon,
    PowerIcon,
} from "@heroicons/react/24/outline";

import useAuth from '../store/hooks/useAuth';

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { show, hide } from '../store/slices/loader.slice';
import { fetchImageProfile, fetchImageProfileSuccess } from '../store/slices/profileImage.slice';

import ApiService from '../services/api/api.service';

const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
    },
    {
      label: "Help",
      icon: LifebuoyIcon,
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
    },
];

function ProfileMenu({imageProfileFile}) {

    const navigate = useNavigate();

    let baseURL = "http://" + window.location.host;

    if (window.location.origin.startsWith('https://')) {
      baseURL = "https://" + window.location.host;
    }

    const logout = async () => {
      const logoutUserResponse = await ApiService.logout();
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeMenu = (lbl) => { 
      setIsMenuOpen(false) 
      switch(lbl) {
        case "Sign Out":
          logout();
          window.location.href = baseURL + "/login";
          break;
        case "My Profile":
          // window.location.href = baseURL;
          navigate("/");
          break;
        case "Messages":
          window.location.href = baseURL + "/messages";
          break;
        case "Help":
            // window.location.href = baseURL + "/help";
            navigate("/help");
            break;
        default:
          navigate("/");
      }
    };
   
    return (
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
          >
            <Avatar
              variant="circular"
              size="sm"
              alt="tania andrew"
              className="border border-blue-500 p-0.5"
              src={imageProfileFile}
            />
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${
                isMenuOpen ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList className="p-1">
          {profileMenuItems.map(({ label, icon }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return (
              <MenuItem
                key={label}
                onClick={() => closeMenu(label)}
                className={`flex items-center gap-2 rounded ${
                  isLastItem
                    ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                    : ""
                }`}
              >
                {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? "red" : "inherit"}
                >
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }


const NavbarMain = ({ onMenuButtonClick }) => {

    const imageProfile = useSelector((state) => state.imageProfileReducer.imageProfile);
    const isFetchingImageProfile = useSelector((state) => state.imageProfileReducer.isFetchingImageProfile);
    const dispatch = useDispatch();

    const { auth } = useAuth();

    const getUserProfile = async () => {
      const formData = new FormData(); 

      formData.append("userid", auth.userid);

      dispatch(show());

      const getUserProfileResponse = await ApiService.getUserProfile(formData);
      dispatch(fetchImageProfileSuccess("/images/members/" + getUserProfileResponse.image_file));
      dispatch(hide());
  }

    useEffect(() => {
      if(!isFetchingImageProfile) {
        dispatch(fetchImageProfile());
      }
    }, []);

    useEffect(() => {
      if(isFetchingImageProfile) {
        getUserProfile();
      }
    }, [isFetchingImageProfile])
    return (
        <nav
            className={classNames({
            "bg-white text-zinc-500": true, // colors
            "flex items-center": true, // layout
            "w-screen md:w-full sticky z-10 px-4 shadow-sm h-[73px] top-0 ": true, //positioning & styling
            })}
        >
            <button className="rounded-2xl p-1 bg-gray-400 bg-opacity-0 hover:bg-opacity-60 transition duration-300 delay-100" onClick={() => onMenuButtonClick()}>
                <Bars3BottomLeftIcon className="h-6 w-6" />
            </button>
            <div className="flex-grow"></div>
            <div>
            { !auth?.accessToken
                        ? null
                        : <ProfileMenu imageProfileFile={imageProfile} /> }
            </div>
        </nav>
    )
}

export default NavbarMain;