import React, { useState, useEffect } from 'react';
import {
    Card,
    Typography,
    Checkbox,
    List,
    ListItem,
    ListItemPrefix    
  } from "@material-tailwind/react";  


const PositionComponent = ({optionsArray, setOptionsArray}) => {

    const handleOnChange = (event, indx) => {
        const values = [...optionsArray];
        values[indx].isSelected = event.target.checked; 
        setOptionsArray(values);
    };

    return (
        <Card className="ml-5 w-full max-w-[30rem]">
            <List className="flex-row">

                {optionsArray.map((item, index) => {
                    return (
                        <ListItem className="p-0" key={index}>
                            <label
                                htmlFor={item.value}
                                className="flex w-full cursor-pointer items-center px-3 py-2"
                            >
                                <ListItemPrefix className="mr-3">
                                    <Checkbox
                                        id={item.value}
                                        value={item.value}
                                        checked={item.isSelected}
                                        onChange={(e) => handleOnChange(e, index)}
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                            className: "p-0",
                                        }}
                                    />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="font-medium">
                                        {item.value}
                                </Typography>
                            </label>
                        </ListItem>
                    )
                })}

            </List>
        </Card>
    )
}

export default PositionComponent;