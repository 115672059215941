import { createSlice } from "@reduxjs/toolkit";

const DepartmentSlice = createSlice({
    name: 'department',
    initialState: {
        error: null,
        isFetchingDepartment: false,
        departments: [],
        selectedDepartment: {}
    },
    reducers: {
        fetchDepartment: (state) => {
            state.isFetchingDepartment = true;
        },
        fetchDepartmentSuccess: (state, action) => {
            state.error = null;
            state.isFetchingDepartment = false;
            state.departments = action.payload;
        },
        getDepartmentByID: (state, action) => {
            state.selectedDepartment = state.departments.filter((item) => item.ID == action.payload);
        },
        fetchDepartmentFail: (state, action) => {
            state.error = action.payload;
            state.isFetchingDepartment = false;
        }
    }
})

export const {fetchDepartment, fetchDepartmentSuccess, fetchDepartmentFail, getDepartmentByID} = DepartmentSlice.actions;

export default DepartmentSlice.reducer;