import { createSlice } from "@reduxjs/toolkit";

const MemberSlice = createSlice({
    name: 'member',
    initialState: {
        error: null,
        isFetchingMembers: false,
        isFetchingMembersByDepartment: false,
        members: [],
        memberByDepartment: [],
        selectedMember: {}
    },
    reducers: {
        fetchMemberByDepartment: (state) => {
            state.isFetchingMembersByDepartment = true;
        },
        fetchMember: (state) => {
            state.isFetchingMembers = true;
        },
        fetchMemberSuccessByDepartment: (state, action) => {
            state.error = null;
            state.isFetchingMembersByDepartment = false;
            state.memberByDepartment = action.payload;
        },
        fetchMemberSuccess: (state, action) => {
            state.error = null;
            state.isFetchingMembers = false;
            state.members = action.payload;
        },
        getMemberByID: (state, action) => {
            state.selectedMember = state.members.filter((item) => item.ID == action.payload);
        },
        fetchMemberFail: (state, action) => {
            state.error = action.payload;
            state.isFetchingMembers = false;
        }
    }
})

export const {fetchMember, fetchMemberSuccess, fetchMemberFail, getMemberByID, fetchMemberByDepartment, fetchMemberSuccessByDepartment} = MemberSlice.actions;

export default MemberSlice.reducer;